import "./App.css";
import "./styles.css";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useFormik } from "formik";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import HeaderLogin from "../../components/global/molecules/HeaderLogin";
import { Carousel } from "react-bootstrap";
import Banner from "../../imgs/BannerLogin.webp";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AttachmentIcon from "@mui/icons-material/Attachment";
import {
  postAssociateInAcsp,
  verifyEmail,
  postCreateAssociate,
} from "../../services/associate/index";
import Dialog from "../../components/dialog";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";

import {
  ErrorMessages,
  SuccessMessages,
  ValidationMessages,
} from "../../helper";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Button, Checkbox } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { MuiFileInput } from "mui-file-input";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import InputMask from "react-input-mask";
import MenuItem from "@mui/material/MenuItem";
import { buscaCep } from "../../services/address";
import { getCnpj } from "../../services/speedioInfoCompany";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { useTour } from "@reactour/tour";
import { validarCNPJOrAssociated } from "../../components/utils/validation";
import Footer from "../../components/landingPage/molecules/Footer";
import LoginMobile from "../loginMobile/index";

export default function Login() {
  const { setCurrentStep } = useTour();
  const [isAssociated, setIsAssociated] = useState(null);
  const [isCreateSubmit, setIsCreateSubmit] = useState(false);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [errorRecaptcha, setErrorRecaptcha] = useState("");
  const theme = createTheme({
    palette: {
      primary: {
        main: "#58C5C6",
      },
      secondary: {
        main: "#f50057",
      },
    },
  });

  useEffect(() => {
    setCurrentStep(0);
  }, []);

  // Monitora resolução de tela
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Criar conta

  const [recaptchaGoogle, setRecaptchaGoogle] = useState(null);

  function onChangeRecaptcha(value) {
    setErrorRecaptcha(null);
    setRecaptchaGoogle(value);
  }

  const [dialogShow, setDialogShow] = useState(false);

  const [backErrors, setBackErrors] = useState([]);
  const clearBackErrors = (field) => {
    backErrors[field] = null;
    setBackErrors(backErrors);
  };

  function resetForm() {
    formik.resetForm();
    formikAssociated.resetForm();
    formik.setTouched({}, false);
    formik.setErrors({}, false);
    formikAssociated.setTouched({}, false);
    formikAssociated.setErrors({});
    setBackErrors({});
  }

  const handleChange = (event) => {
    resetForm();
    setIsAssociated(event.target.value);
  };

  const setData = (dados) => {
    formikAssociated.setFieldValue(
      "createAccountEmail",
      dados.emailcontato.trim()?.toLowerCase()
    );
    formikAssociated.setFieldValue("companyName", dados.razao_social.trim());
    formikAssociated.setFieldValue("createAccountCnpj", dados.cpfcpnj.trim());
    formikAssociated.setFieldValue(
      "createAccountAssociatedCode",
      dados.codigo.trim()
    );
  };

  const handleBlurBuscaCep = async () => {
    setLoadingBackdrop(true);
    await buscaCep(formik.values.zipcode)
      .then((res) => {
        if (res && res.data && res.data.erro) {
          setBackErrors({
            ["zipcode"]: ValidationMessages.INVALID_ZIPCODE_MESSAGE,
          });
        } else {
          formik.setFieldValue("neighborhood", res.data.bairro);
          formik.setFieldValue("address", res.data.logradouro);
          formik.setFieldValue("city", res.data.localidade);
          formik.setFieldValue("state", res.data.uf);
          setBackErrors({ ["zipcode"]: false });
        }
        setLoadingBackdrop(false);
      })
      .catch((err) => {
        setLoadingBackdrop(false);
        setBackErrors({
          ["zipcode"]: ValidationMessages.INVALID_ZIPCODE_MESSAGE,
        });
      });
  };

  const setDataCorporateName = (dados, associated) => {
    if (associated) {
      formikAssociated.setFieldValue(
        "companyName",
        dados["RAZAO SOCIAL"].trim()?.toLowerCase()
      );
    } else {
      if (dados["RAZAO SOCIAL"]) {
        formik.setFieldValue(
          "companyName",
          dados["RAZAO SOCIAL"].trim()?.toLowerCase()
        );
      }
    }
  };

  async function handleBlurSearchCorporateName(cnpj, associated = false) {
    const cnpjFormatted = cnpj.replace(/[^0-9]/g, "");

    if (cnpjFormatted.length == 9) {
      return;
    }
    if (cnpjFormatted.length < 13) {
      return;
    }

    if (validarCNPJOrAssociated(cnpjFormatted, 1)) {
      setLoadingBackdrop(true);
      await postAssociateInAcsp({
        userAssociate: cnpjFormatted,
        verifyExists: true,
      })
        .then(async (response) => {
          clearBackErrors("cnpj");
          await getCnpj(cnpjFormatted)
            .then((response) => {
              setDataCorporateName(response.data, associated);

              setLoadingBackdrop(false);
            })
            .catch((err) => {
              setLoadingBackdrop(false);
            });
        })
        .catch((err) => {
          setLoadingBackdrop(false);
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.errors
          ) {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              const { fieldName, message } = err.response.data.errors[i];
              if (fieldName) {
                if (err.response.status == 409) {
                  toast.error(
                    "Usuário é um associado, por favor preencha os dados no formulário correto."
                  );
                  setIsAssociated("true");
                } else {
                  setBackErrors({ [fieldName]: message });
                }

                if (err.response.status == 422) {
                  setBackErrors({
                    cnpj: "O CNPJ informado já possui cadastro no sistema.",
                  });
                }
              }
            }
          }
        });
    } else {
      setBackErrors({ cnpj: "CNPJ Inválido" });
    }
  }

  async function handleBlurSearchEmail(email) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!email || !emailPattern.test(email)) {
      setBackErrors({ email: "E-mail inválido" });
      return;
    }

    if (email) {
      setLoadingBackdrop(true);
      await verifyEmail(email)
        .then(async (response) => {
          clearBackErrors("email");
          setLoadingBackdrop(false);
        })
        .catch((err) => {
          setLoadingBackdrop(false);
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.errors
          ) {
            for (let i = 0; i < err.response.data.errors.length; i++) {
              const { fieldName, message } = err.response.data.errors[i];

              if (err.response.status === 422) {
                setBackErrors({
                  email: "O e-mail informado já possui cadastro no sistema.",
                });
                return;
              }

              if (fieldName) {
                if (err.response.status == 409) {
                  toast.error(
                    "Usuário é um associado, por favor preencha os dados no formulário correto."
                  );
                  setIsAssociated("true");
                } else {
                  setBackErrors({ [fieldName]: message });
                }
              }
            }
          }
        });
    } else {
      setBackErrors({ email: "Campo obrigatório" });
    }
  }

  const handleBlurCPNJCODE = async () => {
    const userAssociate = formikAssociated.values.createAccountCnpj.replace(
      /[^0-9]/g,
      ""
    );

    if (userAssociate.length === 8 || userAssociate.length === 14) {
      if (validarCNPJOrAssociated(userAssociate, 0)) {
        setLoadingBackdrop(true);
        await postAssociateInAcsp({
          userAssociate: userAssociate,
          verifyExists: false,
        })
          .then((response) => {
            setLoadingBackdrop(false);
            setData(response.data.dados);
            clearBackErrors("cnpj");
          })
          .catch((err) => {
            setLoadingBackdrop(false);

            if (
              err &&
              err.response &&
              err.response.data &&
              err.response.data.errors
            ) {
              for (let i = 0; i < err.response.data.errors.length; i++) {
                if (err.response.status === 404) {
                  toast.error(
                    "Usuário não é um associado, por favor preencha os dados no formulário correto."
                  );

                  setIsAssociated("false");
                  return;
                }
              }

              if (
                err.response.status === 422 &&
                err.response.data.errors[0].fieldName === "code"
              ) {
                setBackErrors({
                  cnpj: "O Código de associado informado já possui cadastro no sistema.",
                });
              } else {
                setBackErrors({
                  cnpj: "O CNPJ informado já possui cadastro no sistema.",
                });
              }
              return;
            }
          });
      } else {
        setBackErrors({ cnpj: "CNPJ Inválido" });
      }
    }
  };

  // usado pra setar valores vindos da api no formulario
  const createAccountFormRef = useRef();

  const createAccountValidationSchema = Yup.object().shape({
    createAccountPhone: Yup.string()
      .min(10, "Telefone precisa ter no mínimo 10 dígitos")
      .required("Campo Obrigatório"),
    companyName: Yup.string()
      .min(2, "Razão social precisa ter no mínimo 2 caracteres")
      .required("Campo Obrigatório"),
    createAccountPassword: Yup.string()
      .nullable()
      .min(8, " Digite no mínimo 8 caracteres")
      .required("Campo Obrigatório"),
    createAccountEmail: Yup.string().email().required("Campo Obrigatório"),
    createAccountPasswordConfirmation: Yup.string().oneOf(
      [Yup.ref("createAccountPassword"), null],
      "Senha e Confirmação de Senha não correspondem"
    ),
    cpf: Yup.string()
      .min(11, "CPF precisa ter no mínimo 11 dígitos")
      .required("Campo Obrigatório"),
    birthDate: Yup.date()
      .required("Campo Obrigatório")
      .max(new Date(), "Data inválida"),
    createAccountTerms: Yup.boolean()
      .nullable()
      .oneOf([true], "Campo obrigatório"),
    loginMethod: Yup.string()
      .required()
      .oneOf(
        ["SOCIAL_CODE", "CERTIFICATE"],
        `Formato inválido, valores disponiveis: ['SOCIAL_CODE', 'CERTIFICATE']`
      ),
    loginSocialCode: Yup.string().when("loginMethod", {
      is: "SOCIAL_CODE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
    loginPassword: Yup.string().when("loginMethod", {
      is: "SOCIAL_CODE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
    certificate: Yup.string().when("loginMethod", {
      is: "CERTIFICATE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
    certificatePassword: Yup.string().when("loginMethod", {
      is: "CERTIFICATE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
  });

  const createAccountWithAssociatedValidationSchema = Yup.object().shape({
    createAccountPhone: Yup.string()
      .min(11, "Telefone precisa ter no mínimo 11 dígitos")
      .required("Campo Obrigatório"),
    createAccountPassword: Yup.string()
      .nullable()
      .min(8, " Digite no mínimo 8 caracteres")
      .required("Campo Obrigatório"),
    zipcode: Yup.string().min(8, "Cep inválido").required("Campo obrigatório"),
    companyName: Yup.string()
      .min(2, "Razão social precisa ter no mínimo 2 caracteres")
      .required("Campo Obrigatório"),
    createAccountEmail: Yup.string().email(),
    createAccountPasswordConfirmation: Yup.string().oneOf(
      [Yup.ref("createAccountPassword"), null],
      "Senha e Confirmação de Senha não correspondem"
    ),
    cpf: Yup.string()
      .min(11, "CPF precisa ter no mínimo 11 dígitos")
      .required("Campo Obrigatório"),
    birthDate: Yup.date()
      .required("Campo Obrigatório")
      .max(new Date(), "Data inválida"),
    createAccountTerms: Yup.boolean()
      .nullable()
      .oneOf([true], "Campo obrigatório"),
    acceptAdeptTerms: Yup.boolean()
      .nullable()
      .oneOf([true], "Campo obrigatório"),

    loginMethod: Yup.string()
      .required()
      .oneOf(
        ["SOCIAL_CODE", "CERTIFICATE"],
        `Formato inválido, valores disponiveis: ['SOCIAL_CODE', 'CERTIFICATE']`
      ),
    loginSocialCode: Yup.string().when("loginMethod", {
      is: "SOCIAL_CODE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
    loginPassword: Yup.string().when("loginMethod", {
      is: "SOCIAL_CODE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
    certificate: Yup.string().when("loginMethod", {
      is: "CERTIFICATE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
    certificatePassword: Yup.string().when("loginMethod", {
      is: "CERTIFICATE",
      then: Yup.string().required("Campo Obrigatório!"),
      otherwise: Yup.string().optional(),
    }),
  });
  const typeLoginList = [
    { value: "SOCIAL_CODE", humanizeName: "Login com CPF e Senha" },
    { value: "CERTIFICATE", humanizeName: "Login com certificado digital" },
  ];
  function dynamicMask() {
    const cnpjClean = formikAssociated.values.createAccountCnpj.replace(
      /[^0-9]/g,
      ""
    );

    if (!cnpjClean || cnpjClean.length === 0) return "999999999999999999";
    if (cnpjClean.length === 8) {
      return "99999999";
    } else {
      return "99.999.999/9999-99";
    }
  }
  const modalRegistrySucess = () => {
    return (
      <div>
        <div className="row">
          {/* <div className="col-md-12 text-center">
                        Cadastro efetuado com Sucesso!!
                    </div> */}
          <div className="col-md-12">
            Por favor, confirme seu endereço de e-mail.
            <br />
            Para confirmá-lo, basta ir na sua caixa de entrada e clicar no link
            que te enviamos.
            <br />
            Caso não tenha recebido o email de ativação ou ele tenha expirado,
            você poderá solicitar o reenvio.
          </div>
        </div>
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      createAccountCnpj: "",
      createAccountPhone: "",
      createAccountEmail: "",
    },

    validationSchema: createAccountWithAssociatedValidationSchema,
    onSubmit: async (values) => {
      setLoadingBackdrop(true);
      if (recaptchaGoogle == null || errorRecaptcha != null) {
        setErrorRecaptcha("É necessário preencher o captcha.");
        return;
      }
      setIsCreateSubmit(true);
      const formData = new FormData();
      if (values.loginMethod === "CERTIFICATE") {
        formData.append("file", values.file);
        formData.append("certificatePassword", values.certificatePassword);
      } else {
        formData.append(
          "loginSocialCode",
          values.loginSocialCode.replace(/[^0-9]/g, "")
        );
        formData.append("loginPassword", values.loginPassword);
      }

      formData.append("cnpj", values.createAccountCnpj.replace(/[^0-9]/g, ""));
      formData.append("cpf", values.cpf.replace(/[^0-9]/g, ""));
      formData.append("rg", values.rg.replace(/[^0-9]/g, ""));
      formData.append("shippingCity", values.city);
      formData.append(
        "shippingPostalCode",
        values.zipcode.replace(/[^0-9]/g, "")
      );
      formData.append("tipoLogradouro", "R");
      formData.append("shippingState", values.state);
      formData.append("shippingStreet", values.address);
      formData.append("shippingNumero", values.number);
      formData.append("shippingBairro", values.neighborhood);
      formData.append("shippingComplemento", values.complement);
      formData.append("email", values.createAccountEmail);
      formData.append(
        "phone",
        values.createAccountPhone.replace(/[^0-9]/g, "")
      );
      formData.append("cellphone", values.cellphone);
      formData.append("razaoSocial", values.companyName);
      formData.append("recaptchaGoogle", recaptchaGoogle);
      formData.append("nome", values.name);
      formData.append("sobrenome", values.surName);
      formData.append("password", values.createAccountPassword);
      formData.append(
        "confirmPassword",
        values.createAccountPasswordConfirmation
      );
      formData.append("birthDate", values.birthDate);
      formData.append("consentForm", values.createAccountTerms);
      formData.append("type", values.loginMethod);
      formData.append("typeRegister", "1");

      await postCreateAssociate(formData)
        .then(() => {
          setLoadingBackdrop(false);
          setDialogShow(true);
          resetForm();
        })
        .catch((e) => {
          setLoadingBackdrop(false);
          if (e && e.response && e.response.status === 422) {
            for (let i = 0; i < e.response.data.errors.length; i++) {
              const { fieldName, message } = e.response.data.errors[i];

              if (fieldName) {
                setBackErrors({ [fieldName]: message });
              } else {
                if (e.response.data && e.response.data.message) {
                  NotificationManager.error(e.response.data.message);
                } else {
                  NotificationManager.error(
                    ErrorMessages.DEFAULT_ERROR_MESSAGE
                  );
                }
              }
            }
          } else {
            if (e?.response?.data?.message) {              
              e.response.data.errors[0].includes("API") && e.response.data.errors[0].includes("GOV.BR") ? NotificationManager.error(e.response.data.errors[0]) : NotificationManager.error(e.response.data.message);
            } else {
              NotificationManager.error(ErrorMessages.NETWORK_ERROR_MESSAGE);
            }
          }
        });
      setIsCreateSubmit(false);
      setLoadingBackdrop(false);
    },
  });

  const formikAssociated = useFormik({
    initialValues: {
      name: "",
      createAccountCnpj: "",
      createAccountPhone: "",
      loginMethod: "SOCIAL_CODE",
    },
    validationSchema: createAccountValidationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    innerRef: createAccountFormRef,
    // validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsCreateSubmit(true);
      if (recaptchaGoogle == null || errorRecaptcha != null) {
        setErrorRecaptcha("É necessário preencher o captcha.");
        return;
      }
      const formData = new FormData();

      if (values.loginMethod === "CERTIFICATE") {
        formData.append("file", values.file);
        formData.append("certificatePassword", values.certificatePassword);
      } else {
        formData.append(
          "loginSocialCode",
          values.loginSocialCode.replace(/[^0-9]/g, "")
        );
        formData.append("loginPassword", values.loginPassword);
      }

      formData.append("cnpj", values.createAccountCnpj.replace(/[^0-9]/g, ""));
      formData.append("cpf", values.cpf.replace(/[^0-9]/g, ""));
      formData.append("email", values.createAccountEmail);
      formData.append(
        "phone",
        values.createAccountPhone.replace(/[^0-9]/g, "")
      );

      formData.append("password", values.createAccountPassword);
      formData.append(
        "confirmPassword",
        values.createAccountPasswordConfirmation
      );
      formData.append("birthDate", values.birthDate);
      formData.append("consentForm", values.createAccountTerms);
      formData.append("type", values.loginMethod);
      formData.append("recaptchaGoogle", recaptchaGoogle);

      formData.append("typeRegister", "0");
      await postCreateAssociate(formData)
        .then(() => {
          setDialogShow(true);
          resetForm();
          // resetForm();
          // resetFormState();
        })
        .catch((e) => {
          if (e && e.response && e.response.status === 422) {
            for (let i = 0; i < e.response.data.errors.length; i++) {
              const { fieldName, message } = e.response.data.errors[i];

              if (fieldName) {
                setBackErrors({ [fieldName]: message });
              } else {
                if (e.response.data && e.response.data.message) {
                  NotificationManager.error(e.response.data.message);
                } else {
                  NotificationManager.error(
                    ErrorMessages.DEFAULT_ERROR_MESSAGE
                  );
                }
              }
            }
          } else {
            if (e && e.response && e.response.data && e.response.data.message) {
              NotificationManager.error(e.response.data.message);
            } else {
              NotificationManager.error(ErrorMessages.NETWORK_ERROR_MESSAGE);
            }
          }
        });
      setIsCreateSubmit(false);
    },
  });

  return (
    <>
      {window.innerWidth > 901 ? (
        <>
          <ThemeProvider theme={theme}>
            <div>
              <HeaderLogin />
              <section>
                <Carousel
                  nextIcon={null}
                  nextLabel={null}
                  prevIcon={null}
                  indicators={false}
                  className="is-desk"
                  arrow
                >
                  <Carousel.Item>
                    <img
                      style={{ marginTop: -20, width: "100%", height: "100%" }}
                      src={Banner}
                      alt="imagem-banner-login"
                      height={600}
                    />
                  </Carousel.Item>
                </Carousel>
              </section>
              <div className="row d-flex justify-content-center mr-0">
                <NotificationContainer />
                <div className="col-7 p-0 logos"></div>
                <div className="col-md-10 d-flex justify-content-center">
                  <div className="col-md-6 p-0">
                    <div>
                      <h1
                        className="createAccount__title font-weight-bold"
                        style={{ marginTop: 30 }}
                      >
                        Criar Conta
                      </h1>
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-radio-buttons-group-label"
                          name="radio-buttons-group"
                          value={isAssociated}
                        >
                          <FormControlLabel
                            sx={{ color: "#002C43" }}
                            value={"true"}
                            onChange={handleChange}
                            control={
                              <Radio
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={
                                  <CheckBoxIcon sx={{ color: "#58C5C6" }} />
                                }
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  color: "#002C43",
                                }}
                              >
                                Associado
                              </Typography>
                            }
                          />
                          <FormControlLabel
                            value={"false"}
                            onChange={handleChange}
                            control={
                              <Radio
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={
                                  <CheckBoxIcon sx={{ color: "#58C5C6" }} />
                                }
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  fontSize: 16,
                                  color: "#002C43",
                                }}
                              >
                                Não sou associado
                              </Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>

                    {isAssociated === "true" ? (
                      <div style={{ marginTop: 25, marginLeft: 0 }}>
                        <form onSubmit={formikAssociated.handleSubmit}>
                          <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            maxWidth={750}
                          >
                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                CNPJ ou Código do Associado
                              </p>
                              <InputMask
                                mask={dynamicMask()}
                                value={
                                  formikAssociated.values.createAccountCnpj
                                }
                                disabled={false}
                                maskChar=""
                                onBlur={() => handleBlurCPNJCODE(true)}
                                onChange={formikAssociated.handleChange}
                              >
                                {() => (
                                  <TextField
                                    fullWidth
                                    name="createAccountCnpj"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="CNPJ ou Código do Associado"
                                    required
                                    variant="outlined"
                                  />
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.createAccountCnpj &&
                                  formikAssociated.touched.createAccountCnpj &&
                                  formikAssociated.errors.createAccountCnpj) ||
                                  backErrors.cnpj}
                              </small>
                            </Grid>

                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                CPF
                              </p>
                              <InputMask
                                mask="999.999.999-99"
                                value={formikAssociated.values.cpf}
                                disabled={false}
                                maskChar=" "
                                onChange={formikAssociated.handleChange}
                              >
                                {() => (
                                  <TextField
                                    fullWidth
                                    name="cpf"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="CPF"
                                    required
                                    variant="outlined"
                                  >
                                    {" "}
                                  </TextField>
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.cpf &&
                                  formikAssociated.touched.cpf &&
                                  formikAssociated.errors.cpf) ||
                                  backErrors.cpf}
                              </small>
                            </Grid>
                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                E-mail
                              </p>
                              <TextField
                                fullWidth
                                disabled
                                name="createAccountEmail"
                                value={
                                  formikAssociated.values.createAccountEmail
                                }
                                onChange={formikAssociated.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="E-mail"
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.createAccountEmail &&
                                  formikAssociated.touched.createAccountEmail &&
                                  formikAssociated.errors.createAccountEmail) ||
                                  backErrors.email}
                              </small>
                            </Grid>

                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Razão Social
                              </p>
                              <TextField
                                fullWidth
                                name="companyName"
                                value={formikAssociated.values.companyName}
                                onChange={formikAssociated.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Razão Social"
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.companyName &&
                                  formikAssociated.touched.companyName &&
                                  formikAssociated.errors.companyName) ||
                                  backErrors.companyName}
                              </small>
                            </Grid>

                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Telefone
                              </p>
                              <InputMask
                                mask="(99) 99999-9999"
                                value={
                                  formikAssociated.values.createAccountPhone
                                }
                                disabled={false}
                                maskChar=" "
                                onChange={formikAssociated.handleChange}
                              >
                                {() => (
                                  <TextField
                                    required
                                    fullWidth
                                    name="createAccountPhone"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Telefone"
                                    variant="outlined"
                                  >
                                    {" "}
                                  </TextField>
                                )}
                              </InputMask>
                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.createAccountPhone &&
                                  formikAssociated.touched.createAccountPhone &&
                                  formikAssociated.errors.createAccountPhone) ||
                                  backErrors.phone}
                              </small>
                            </Grid>
                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Data de Nascimento
                              </p>
                              <TextField
                                fullWidth
                                type="date"
                                name="birthDate"
                                value={formikAssociated.values.birthDate}
                                onChange={formikAssociated.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CalendarMonthIcon
                                        sx={{ color: "#58C5C6" }}
                                      />
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    min: "1920-01-01",
                                    max: "2020-05-04",
                                  },
                                }}
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.birthDate &&
                                  formikAssociated.touched.birthDate &&
                                  formikAssociated.errors.birthDate) ||
                                  backErrors.birthDate}
                              </small>
                            </Grid>

                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Senha
                              </p>
                              <TextField
                                fullWidth
                                type="password"
                                name="createAccountPassword"
                                value={
                                  formikAssociated.values.createAccountPassword
                                }
                                onChange={formikAssociated.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Senha"
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors
                                  .createAccountPassword &&
                                  formikAssociated.touched
                                    .createAccountPassword &&
                                  formikAssociated.errors
                                    .createAccountPassword) ||
                                  backErrors.password}
                              </small>
                            </Grid>

                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Confirmar Senha
                              </p>
                              <TextField
                                fullWidth
                                type="password"
                                required
                                name="createAccountPasswordConfirmation"
                                value={
                                  formikAssociated.values
                                    .createAccountPasswordConfirmation
                                }
                                onChange={formikAssociated.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Confirme sua Senha"
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors
                                  .createAccountPasswordConfirmation &&
                                  formikAssociated.touched
                                    .createAccountPasswordConfirmation &&
                                  formikAssociated.errors
                                    .createAccountPasswordConfirmation) ||
                                  backErrors.password}
                              </small>
                            </Grid>

                            <Grid item xs={12}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Método de Login do site{" "}
                                <span
                                  style={{
                                    color: "#58C5C6",
                                    textDecoration: "underline",
                                  }}
                                >
                                  gov.br
                                </span>
                              </p>
                              <TextField
                                required
                                select
                                fullWidth
                                name="loginMethod"
                                value={formikAssociated.values.loginMethod}
                                onChange={formikAssociated.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                              >
                                {typeLoginList.map((i) => (
                                  <MenuItem key={i.value} value={i.value}>
                                    {i.humanizeName}
                                  </MenuItem>
                                ))}
                              </TextField>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.loginMethod &&
                                  formikAssociated.touched.loginMethod &&
                                  formikAssociated.errors.loginMethod) ||
                                  backErrors.loginMethod}
                              </small>
                            </Grid>
                            {/* {typeLoginList.map((i) => (
                 <option key={i.value} value={i.value}>
                   {i.humanizeName}
                 </option> */}
                            {formikAssociated.values.loginMethod ===
                            "SOCIAL_CODE" ? (
                              <>
                                <Grid item xs={6}>
                                  <TextField
                                    name="loginSocialCode"
                                    fullWidth
                                    value={
                                      formikAssociated.values.loginSocialCode
                                    }
                                    onChange={formikAssociated.handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="CPF"
                                    variant="outlined"
                                  ></TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    type="password"
                                    name="loginPassword"
                                    fullWidth
                                    value={
                                      formikAssociated.values.loginPassword
                                    }
                                    onChange={formikAssociated.handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Senha"
                                    variant="outlined"
                                  />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "18px",
                                      color: "#FFF",
                                      backgroundColor: "#58C5C6",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <MuiFileInput
                                      fullWidth
                                      name="file"
                                      value={formikAssociated.values.file}
                                      onChange={(e) => {
                                        formikAssociated.setFieldValue(
                                          "file",
                                          e
                                        );
                                        formikAssociated.setFieldValue(
                                          "certificate",
                                          e
                                        );
                                      }}
                                      inputProps={{
                                        style: {
                                          height: "15px",
                                        },
                                      }}
                                    />
                                    <div
                                      style={{
                                        position: "absolute",
                                      }}
                                    >
                                      {formikAssociated.values.file == null
                                        ? "Certificado Digital"
                                        : ""}
                                    </div>
                                  </div>
                                  <small className="col-12 text-danger m-0 p-0">
                                    {(formikAssociated.errors.certificate &&
                                      formikAssociated.touched.certificate &&
                                      formikAssociated.errors.certificate) ||
                                      backErrors.mimetype}
                                  </small>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    type="password"
                                    name="certificatePassword"
                                    fullWidth
                                    value={
                                      formikAssociated.values
                                        .certificatePassword
                                    }
                                    onChange={formikAssociated.handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Senha do Certificado"
                                    variant="outlined"
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12}>
                              <font color="#002C43">
                                {" "}
                                Caso as informações preenchidas automaticamente
                                estejam divergentes ou desatualizadas, favor
                                entrar em contato com a ACSP através do número
                                (11) 3180-3737{" "}
                              </font>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControlLabel
                                onChange={(e) =>
                                  formikAssociated.setFieldValue(
                                    "createAccountTerms",
                                    e.target.checked
                                  )
                                }
                                control={<Checkbox name="createAccountTerms" />}
                                value={
                                  formikAssociated.values.createAccountTerms
                                }
                                label={
                                  <div>
                                    <font color="#002C43">
                                      Eu li e concordo com os{" "}
                                    </font>{" "}
                                    <a href="/information/use_terms">
                                      {" "}
                                      <font color="#58C5C6">
                                        <u>
                                          termos de uso e políticas de
                                          privacidade
                                        </u>
                                      </font>
                                    </a>
                                  </div>
                                }
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.createAccountTerms &&
                                  formikAssociated.touched.createAccountTerms &&
                                  formikAssociated.errors.createAccountTerms) ||
                                  backErrors.createAccountTerms}
                              </small>
                            </Grid>
                            <Grid item xs={12}>
                              <ReCAPTCHA
                                sitekey="6LeWoFkeAAAAAHQp_aaprWIWCPqNVJgug7sja2Cu"
                                onChange={onChangeRecaptcha}
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {errorRecaptcha || backErrors.recaptcha}
                              </small>
                            </Grid>
                            <Grid item xs={12}>
                              <ThemeProvider theme={theme}>
                                <Button
                                  sx={{
                                    minWidth: "192px",
                                    height: "42px",
                                    color: "#FFF",
                                    textTransform: "none",
                                    boxShadow: "none",
                                    "&:hover": {
                                      backgroundColor: "#FFF",
                                      color: "#58C5C6",
                                      border: "1px solid #58C5C6",
                                      boxShadow: "none",
                                    },
                                  }}
                                  variant="contained"
                                  type="submit"
                                >
                                  Criar Conta
                                </Button>
                              </ThemeProvider>
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    ) : isAssociated === "false" ? (
                      <div style={{ marginTop: 25, marginLeft: 0 }}>
                        <form onSubmit={formik.handleSubmit}>
                          <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                            maxWidth={750}
                          >
                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Nome
                              </p>
                              <TextField
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Nome"
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.name &&
                                  formik.touched.name &&
                                  formik.errors.name) ||
                                  backErrors.name}
                              </small>
                            </Grid>
                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Sobrenome
                              </p>
                              <TextField
                                name="surName"
                                value={formik.values.surName}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Sobrenome"
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.surName &&
                                  formik.touched.surName &&
                                  formik.errors.surName) ||
                                  backErrors.surName}
                              </small>
                            </Grid>
                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Data de nascimento
                              </p>
                              <TextField
                                fullWidth
                                type={"date"}
                                name="birthDate"
                                value={formik.values.birthDate}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <CalendarMonthIcon
                                        sx={{ color: "#58C5C6" }}
                                      />
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    min: "1920-01-01",
                                    max: "2020-05-04",
                                  },
                                }}
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.birthDate &&
                                  formik.touched.birthDate &&
                                  formik.errors.birthDate) ||
                                  backErrors.birthDate}
                              </small>
                            </Grid>
                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                CNPJ
                              </p>
                              <InputMask
                                mask="99.999.999/9999-99"
                                value={formik.values.createAccountCnpj}
                                disabled={false}
                                maskChar=" "
                                onBlur={() =>
                                  handleBlurSearchCorporateName(
                                    formik.values.createAccountCnpj,
                                    false
                                  )
                                }
                                onChange={formik.handleChange}
                              >
                                {() => (
                                  <TextField
                                    required
                                    fullWidth
                                    name="createAccountCnpj"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="CNPJ"
                                    variant="outlined"
                                  ></TextField>
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.createAccountCnpj &&
                                  formik.touched.createAccountCnpj &&
                                  formik.errors.createAccountCnpj) ||
                                  backErrors.cnpj}
                              </small>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                CPF
                              </p>
                              <InputMask
                                mask="999.999.999-99"
                                value={formik.values.cpf}
                                disabled={false}
                                maskChar=" "
                                onChange={formik.handleChange}
                              >
                                {() => (
                                  <TextField
                                    required
                                    fullWidth
                                    name="cpf"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="CPF"
                                    variant="outlined"
                                  >
                                    {" "}
                                  </TextField>
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.cpf &&
                                  formik.touched.cpf &&
                                  formik.errors.cpf) ||
                                  backErrors.cpf}
                              </small>
                            </Grid>
                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                RG
                              </p>
                              <InputMask
                                mask="99.999.999-9"
                                value={formik.values.rg}
                                disabled={false}
                                maskChar=" "
                                onChange={formik.handleChange}
                              >
                                {() => (
                                  <TextField
                                    name="rg"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="RG"
                                    required
                                    variant="outlined"
                                  />
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.rg &&
                                  formik.touched.rg &&
                                  formik.errors.rg) ||
                                  backErrors.rg}
                              </small>
                            </Grid>
                            <Grid item xs={12}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Razão Social
                              </p>
                              <TextField
                                name="companyName"
                                value={formik.values.companyName}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Razão Social"
                                variant="outlined"
                                sx={{ width: "100%" }}
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.companyName &&
                                  formik.touched.companyName &&
                                  formik.errors.companyName) ||
                                  backErrors.companyName}
                              </small>
                            </Grid>
                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                E-mail
                              </p>
                              <TextField
                                type="email"
                                required
                                name="createAccountEmail"
                                value={formik.values.createAccountEmail}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="E-mail"
                                variant="outlined"
                                onBlur={() =>
                                  handleBlurSearchEmail(formik.values.createAccountEmail)
                                }
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.createAccountEmail &&
                                  formik.touched.createAccountEmail &&
                                  formik.errors.createAccountEmail) ||
                                  backErrors.email}
                              </small>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Confirmar E-mail
                              </p>
                              <TextField
                                name="confirmEmail"
                                type="email"
                                required
                                value={formik.values.confirmEmail}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Confirmar e-mail"
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.confirmEmail &&
                                  formik.touched.confirmEmail &&
                                  formik.errors.confirmEmail) ||
                                  backErrors.confirmEmail}
                              </small>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Telefone
                              </p>
                              <InputMask
                                mask="(99) 9999-9999"
                                value={formik.values.createAccountPhone}
                                disabled={false}
                                maskChar=" "
                                onChange={formik.handleChange}
                              >
                                {() => (
                                  <TextField
                                    fullWidth
                                    name="createAccountPhone"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Telefone"
                                    required
                                    variant="outlined"
                                  >
                                    {" "}
                                  </TextField>
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.createAccountPhone &&
                                  formik.touched.createAccountPhone &&
                                  formik.errors.createAccountPhone) ||
                                  backErrors.createAccountPhone}
                              </small>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Celular
                              </p>
                              <InputMask
                                mask="(99) 99999-9999"
                                value={formik.values.cellphone}
                                disabled={false}
                                maskChar=" "
                                onChange={formik.handleChange}
                              >
                                {() => (
                                  <TextField
                                    required
                                    fullWidth
                                    name="cellphone"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Celular"
                                    variant="outlined"
                                  ></TextField>
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.cellphone &&
                                  formik.touched.cellphone &&
                                  formik.errors.cellphone) ||
                                  backErrors.cellphone}
                              </small>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                CEP
                              </p>
                              <InputMask
                                mask="99999-999"
                                value={formik.values.zipcode}
                                disabled={false}
                                maskChar=" "
                                onChange={formik.handleChange}
                                onBlur={() => handleBlurBuscaCep()}
                              >
                                {() => (
                                  <TextField
                                    required
                                    name="zipcode"
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="CEP"
                                    variant="outlined"
                                  />
                                )}
                              </InputMask>

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.zipcode &&
                                  formik.touched.zipcode &&
                                  formik.errors.zipcode) ||
                                  backErrors.zipcode}
                                <br></br>
                              </small>
                              <a
                                href="https://buscacepinter.correios.com.br/app/endereco/index.php"
                                target="_blank"
                                className="link"
                              >
                                Não sei o CEP
                              </a>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Endereço
                              </p>
                              <TextField
                                fullWidth
                                name="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                placeholder="Endereço"
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.address &&
                                  formik.touched.address &&
                                  formik.errors.address) ||
                                  backErrors.address}
                              </small>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Bairro
                              </p>
                              <TextField
                                name="neighborhood"
                                value={formik.values.neighborhood}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                required
                                placeholder="Bairro"
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.neighborhood &&
                                  formik.touched.neighborhood &&
                                  formik.errors.neighborhood) ||
                                  backErrors.neighborhood}
                              </small>
                            </Grid>

                            <Grid item xs={4}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Cidade
                              </p>
                              <TextField
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Cidade"
                                required
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.city &&
                                  formik.touched.city &&
                                  formik.errors.city) ||
                                  backErrors.city}
                              </small>
                            </Grid>

                            <Grid item xs={2}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Número
                              </p>
                              <TextField
                                name="number"
                                value={formik.values.number}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Nº"
                                required
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.number &&
                                  formik.touched.number &&
                                  formik.errors.number) ||
                                  backErrors.number}
                              </small>
                            </Grid>

                            <Grid item xs={2}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                UF
                              </p>
                              <TextField
                                name="state"
                                value={formik.values.state}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="UF"
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.state &&
                                  formik.touched.state &&
                                  formik.errors.state) ||
                                  backErrors.state}
                              </small>
                            </Grid>

                            <Grid item xs={12}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Complemento
                              </p>
                              <TextField
                                name="complement"
                                value={formik.values.complement}
                                onChange={formik.handleChange}
                                fullWidth
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Complemento"
                                variant="outlined"
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.complement &&
                                  formik.touched.complement &&
                                  formik.errors.complement) ||
                                  backErrors.complement}
                              </small>
                            </Grid>
                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Senha
                              </p>
                              <TextField
                                name="createAccountPassword"
                                value={formik.values.createAccountPassword}
                                onChange={formik.handleChange}
                                fullWidth
                                type="password"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Senha"
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.createAccountPassword &&
                                  formik.touched.createAccountPassword &&
                                  formik.errors.createAccountPassword) ||
                                  backErrors.password}
                              </small>
                            </Grid>
                            <Grid item xs={6}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Confirmar Senha
                              </p>
                              <TextField
                                name="createAccountPasswordConfirmation"
                                value={
                                  formik.values
                                    .createAccountPasswordConfirmation
                                }
                                onChange={formik.handleChange}
                                fullWidth
                                type="password"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Confirme sua Senha"
                                required
                                variant="outlined"
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors
                                  .createAccountPasswordConfirmation &&
                                  formik.touched
                                    .createAccountPasswordConfirmation &&
                                  formik.errors
                                    .createAccountPasswordConfirmation) ||
                                  backErrors.password}
                              </small>
                            </Grid>

                            <Grid item xs={12}>
                              <p
                                style={{
                                  fontFamily: "Source Sans Pro",
                                  color: "#7B7B7B",
                                  fontSize: "18px",
                                  marginBottom: "5px",
                                }}
                              >
                                Método de Login do site{" "}
                                <span
                                  style={{
                                    color: "#58C5C6",
                                    textDecoration: "underline",
                                  }}
                                >
                                  gov.br
                                </span>
                              </p>
                              <TextField
                                select
                                fullWidth
                                name="loginMethod"
                                value={formik.values.loginMethod}
                                onChange={formik.handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                              >
                                {typeLoginList.map((i) => (
                                  <MenuItem key={i.value} value={i.value}>
                                    {i.humanizeName}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.loginMethod &&
                                  formik.touched.loginMethod &&
                                  formik.errors.loginMethod) ||
                                  backErrors.password}
                              </small>
                            </Grid>
                            {/* {typeLoginList.map((i) => (
                 <option key={i.value} value={i.value}>
                   {i.humanizeName}
                 </option> */}
                            {formik.values.loginMethod === "SOCIAL_CODE" ? (
                              <>
                                <Grid item xs={6}>
                                  <p
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      color: "#7B7B7B",
                                      fontSize: "18px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    CPF
                                  </p>
                                  <TextField
                                    name="loginSocialCode"
                                    fullWidth
                                    value={formik.values.loginSocialCode}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="CPF"
                                    variant="outlined"
                                  ></TextField>
                                </Grid>
                                <Grid item xs={6}>
                                  <p
                                    style={{
                                      fontFamily: "Source Sans Pro",
                                      color: "#7B7B7B",
                                      fontSize: "18px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Senha
                                  </p>
                                  <TextField
                                    name="loginPassword"
                                    type="password"
                                    fullWidth
                                    value={formik.values.loginPassword}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Senha"
                                    variant="outlined"
                                  />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item xs={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      fontSize: "18px",
                                      color: "#FFF",
                                      backgroundColor: "#58C5C6",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <MuiFileInput
                                      fullWidth
                                      name="file"
                                      value={formik.values.file}
                                      onChange={(e) => {
                                        formik.setFieldValue("file", e);
                                        formik.setFieldValue("certificate", e);
                                      }}
                                      inputProps={{
                                        style: {
                                          height: "15px",
                                        },
                                      }}
                                    />

                                    <div
                                      style={{
                                        position: "absolute",
                                      }}
                                    >
                                      {formik.values.file == null
                                        ? "Certificado Digital"
                                        : ""}
                                    </div>
                                  </div>
                                  <small className="col-12 text-danger m-0 p-0">
                                    {(formik.errors.certificate &&
                                      formik.touched.certificate &&
                                      formik.errors.certificate) ||
                                      backErrors.mimetype}
                                  </small>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    type="password"
                                    name="certificatePassword"
                                    fullWidth
                                    value={formik.values.certificatePassword}
                                    onChange={formik.handleChange}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    placeholder="Senha do Certificado"
                                    variant="outlined"
                                  />
                                </Grid>
                              </>
                            )}
                            <Grid item xs={12}>
                              <font color="#002C43">
                                {" "}
                                Caso as informações preenchidas automaticamente
                                estejam divergentes ou desatualizadas, favor
                                entrar em contato com a ACSP através do número
                                (11) 3180-3737{" "}
                              </font>
                            </Grid>

                            <Grid item xs={12}>
                              <FormControlLabel
                                onChange={(e) =>
                                  formik.setFieldValue(
                                    "createAccountTerms",
                                    e.target.checked
                                  )
                                }
                                control={<Checkbox name="createAccountTerms" />}
                                value={formik.values.createAccountTerms}
                                label={
                                  <div>
                                    <font color="#002C43">
                                      Eu li e concordo com os{" "}
                                    </font>{" "}
                                    <a href="/information/use_terms">
                                      {" "}
                                      <font color="#58C5C6">
                                        <u>
                                          termos de uso e políticas de
                                          privacidade
                                        </u>
                                      </font>
                                    </a>
                                  </div>
                                }
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formik.errors.createAccountTerms &&
                                  formik.touched.createAccountTerms &&
                                  formik.errors.createAccountTerms) ||
                                  backErrors.createAccountTerms}
                              </small>
                            </Grid>

                            <Grid style={{ marginTop: -25 }} item xs={12}>
                              <FormControlLabel
                                onChange={(e) =>
                                  formikAssociated.setFieldValue(
                                    "createAccountTerms",
                                    e.target.checked
                                  )
                                }
                                control={<Checkbox name="acceptAdeptTerms" />}
                                value={formikAssociated.values.acceptAdeptTerms}
                                label={
                                  <div>
                                    <font color="#002C43">
                                      Estou de acordo em me tornar um Associado
                                      da categoria Participante.{" "}
                                    </font>{" "}
                                    <br />
                                    <a href="/docs/termo_adesao_associado_participante.pdf">
                                      {" "}
                                      <font color="#58C5C6">
                                        Clique Aqui{" "}
                                        <AttachmentIcon
                                          style={{ marginBottom: 2 }}
                                        />
                                      </font>
                                    </a>
                                    <font color="#002c43">
                                      {" "}
                                      e acesse o Termo de Adesão.
                                    </font>
                                  </div>
                                }
                              />

                              <small className="col-12 text-danger m-0 p-0">
                                {(formikAssociated.errors.acceptAdeptTerms &&
                                  formikAssociated.touched.acceptAdeptTerms &&
                                  formikAssociated.errors.acceptAdeptTerms) ||
                                  backErrors.acceptAdeptTerms}
                              </small>
                            </Grid>

                            <Grid item xs={12}>
                              <ReCAPTCHA
                                sitekey="6LeWoFkeAAAAAHQp_aaprWIWCPqNVJgug7sja2Cu"
                                onChange={onChangeRecaptcha}
                              />
                              <small className="col-12 text-danger m-0 p-0">
                                {errorRecaptcha || backErrors.recaptcha}
                              </small>
                            </Grid>
                            <Grid item xs={12}>
                              <ThemeProvider theme={theme}>
                                <Button
                                  sx={{
                                    minWidth: "192px",
                                    height: "42px",
                                    marginBottom: 2,
                                    color: "#FFF",
                                    textTransform: "none",
                                    boxShadow: "none",
                                    "&:hover": {
                                      backgroundColor: "#FFF",
                                      color: "#58C5C6",
                                      border: "1px solid #58C5C6",
                                      boxShadow: "none",
                                    },
                                  }}
                                  variant="contained"
                                  type="submit"
                                >
                                  Criar conta
                                </Button>
                              </ThemeProvider>
                            </Grid>
                          </Grid>
                        </form>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <Dialog
                dialogData={{
                  showModal: dialogShow,
                  buttonClass: "invisible",
                  showHeader: true,
                  showBody: true,
                  dialogTitle: SuccessMessages.DEFAULT_REGISTER_SUCCESS_MESSAGE,
                  dialogBody: modalRegistrySucess(),
                  customSetState: setDialogShow,
                }}
              />
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={loadingBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              <div
                style={{
                  marginTop:
                    isAssociated !== "true" && isAssociated !== "false"
                      ? "500px"
                      : "100px",
                }}
              >
                <Footer />
              </div>
            </div>
          </ThemeProvider>
        </>
      ) : (
        <LoginMobile />
      )}
    </>
  );
}
