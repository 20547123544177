import "../App.css";

import {
  faArrowLeft,
  faClipboardList,
  faExclamationTriangle,
  faEye,
  faFileDownload,
  faTimesCircle,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik } from "formik";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { Button, Toast } from "react-bootstrap";
import { Button as ButtonMaterial } from "@mui/material";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import ButtonActionDefault from "../../../components/buttonAction";
import ButtonSubmit from "../../../components/buttonSubmit";
import DialogAutoOpen from "../../../components/dialogAutoOpen";
import Currency from "../../../components/inputs/currency";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import PageDefault from "../../../components/pageDefault";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import {
  cancelNFe,
  findAllNfe,
  amountNfsIssued,
  correctionNfe,
  returnNfe,
  consultaNfe
} from "../../../services/associate/nfe";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import { SubscriptionPlan } from "../../../enumerations/Associated/subscriptionPlan";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import typeInvoiceEnum from "../../../enumerations/typeInvoiceEnum";
import ModalWarningUpdate from "../../../components/global/ModalWarningUpdate";
import {
  NotificationManager,
} from "react-notifications";

function InvoiceProduct() {
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const AlreadyShown = sessionStorage.getItem("WarningUpdateShownProduct");
    if (!AlreadyShown) {
      setOpenModal(true);
      sessionStorage.setItem("WarningUpdateShownProduct", true);
    }
  }, []);
  const [invoiceProduct, setInvoiceProduct] = useState([]);
  const [invoiceProductInfo, setInvoiceProductInfo] = useState({});
  const [anchorEl, setAnchorEl] = useState([]);
  const open = Boolean(anchorEl);
  const handleClick = (event, idx) => {
    let array = [...anchorEl];
    array[idx] = event.currentTarget;
    setAnchorEl(array);
  };

  const handleClose = (event, idx) => {
    let array = [...anchorEl];
    array.splice(idx, 1);
    setAnchorEl(array);
  };

  const [showModalCancellation, setShowModalCancellation] = useState(false);
  const [showModalJustification, setShowModalJustification] = useState(false);
  const [showModalCorrection, setShowModalCorrection] = useState(false);

  const [nfeId, setNfeId] = useState(undefined);
  const [requestStatus, setRequestStatus] = useState(null);
  const [backendErros, setBackendErrors] = useState(undefined);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);

  const [limitNfIssuedReached, setLimitNfIssuedReached] = useState(false);
  const [planoAtivo, setplanoAtivo] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const schema = Yup.object().shape({
    observation: Yup.string()
      .typeError("Campo inválido")
      .required("Campo obrigatório!")
      .min(15, "O campo aceita no mínimo 15 e no máximo 255 caracteres")
      .max(255, "O campo aceita no mínimo 15 e no máximo 255 caracteres"),
  });

  const schemaCorrection = Yup.object().shape({
    observation: Yup.string()
      .typeError("Campo inválido")
      .required("Campo obrigatório!")
      .min(15, "O campo aceita no mínimo 15 e no máximo 1000 caracteres")
      .max(1000, "O campo aceita no mínimo 15 e no máximo 1000 caracteres"),
  });

  const options = {
    selectableRows: false,
    serverSide: true,
    count: invoiceProductInfo.totalResults,
    rowsPerPageOptions: [10, 20, 50, 100],
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          pagination(tableState);
          break;
        case "changeRowsPerPage":
          changeRowsperPage(tableState);
          break;
        default:
      }
    },
    download: false,
    search: false,
    filter: false,
    print: false,
    viewColumns:false,
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado!",
        toolTip: "Ordenação",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página: ",
      },
      filter: {
        all: "Todos resultados",
        title: "Seus filtros",
        reset: "",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download csv",
        filterTable: "Filtrar tabela",
        print: "Imprimir",
        viewColumns: "Filtro de coluna",
      },
    },
  };

  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
    const token = localStorage.getItem("jwt_auth");
    const { planoAssinatura } = jwt_decode(token);

    setplanoAtivo(true);
    if (currentDate > planoAssinatura.dataTerminoVigencia) {
      setplanoAtivo(false);
    }
    console.log(planoAssinatura);
    amountNfsIssued()
      .then(({ data }) => {
        console.log(data);
        if (planoAssinatura !== null) {
          if (
            planoAssinatura.id === SubscriptionPlan.FREE &&
            data.amount >= planoAssinatura.limiteEmissaoNF
          ) {
            setLimitNfIssuedReached(true);
          } else {
            setLimitNfIssuedReached(false);
          }
        }
      })
      .catch((error) => console.log(error));

    findAllNfe({ type: 0 })
      .then(({ data }) => {
        setInvoiceProduct(data.results);
        setInvoiceProductInfo(data);
      })
      .catch((error) => console.log(error));
  }, []);

  const pagination = (data) => {
    findAllNfe({
      page: data.page + 1,
      perPage: data.rowsPerPage,
      type: 0,
    }).then((response) => {
      setInvoiceProduct([]);
      setInvoiceProduct(response.data.results);
      setInvoiceProductInfo(response.data);
    });
  };

  const changeRowsperPage = (data) => {
    findAllNfe({
      perPage: data.rowsPerPage,
    }).then((response) => {
      setInvoiceProduct(response.data.results);
      setInvoiceProductInfo(response.data);
    });
  };

  const columns = [
    {
      name: "id",
      options: {
        display: "none", // Oculta a coluna
      },
    },
    {
      name: "Série-NF",
      options: {
        filter: true,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
      },
    },
    {
      name: "Nº Nota Fiscal",
      options: {
        filter: true,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
      },
    },
    {
      name: "Data de emissão:",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
      },
    },
    {
      name: "Cliente",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
      },
    },
    {
      name: "Valor da nota:",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: (value) => ({
          style: { textAlign: "left", width: 200 },
        }),
      },
    },
    {
      name: "Status de processamento:",
      options: {
        filter: true,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
        options: {
          filter: true,
          sort: true,
          setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
          // customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
          //   console.log(value,'test')
          //   return (
          //   <Tooltip disableHoverListener={invoiceProduct[tableMeta.rowIndex]?.codStatus === 100 ?  false : true} title={`${invoiceProduct[tableMeta.rowIndex].motivo}`}>
          //   <span><center>teste</center></span>
          // </Tooltip>
          // )
          // }
        },
      },
    },

    {
      name: "Ações:",
      options: {
        filter: false,
        sort: false,
        print: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
      },
    },
  ];

  const showCancelationModal = (id) => {
    setShowModalCancellation(true);
    setNfeId(id);
  };

  const showJustificationModal = (id) => {
    setShowModalJustification(true);
    setNfeId(id);
  };

  const showCorrectionModal = (id) => {
    setShowModalCorrection(true);
    setNfeId(id);
  };

  const handleStatus = (status) => {
    switch (status) {
      case true: {
        return (
          <span className="text-success mt-2">
            Solicitação de cancelamento da nota fiscal efetuada com sucesso
          </span>
        );
      }
      case false: {
        return (
          <span className="text-danger mt-2">
            {backendErros?.errors || "Erro ao processar solicitação!"}
          </span>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  const handleInvoiceReturnSubmit = async ({ data }) => {
    setLoadingBackdrop(true);
    const { observation, nfeId } = data;

    await returnNfe({
      id: nfeId,
      body: { idBasket: nfeId, justification: observation },
    })
      .then(() => {
        setRequestStatus(true);
        setShowModalJustification(false);
        setRequestStatus(undefined);
        toast.success("Nota fiscal devolvida com sucesso.");
        window.location.reload();
      })
      .catch((err) => {
        setLoadingBackdrop(false);
        setRequestStatus(false);

        if (err.response && Array.isArray(err.response?.data?.errors)) {
          err.response.data.errors.forEach((e) =>
            setBackendErrors({ [e.fieldName]: e.message })
          );
        }
      });
    setLoadingBackdrop(false);
  };
  const handleInvoiceCorrectionSubmit = async ({ data }) => {
    setLoadingBackdrop(true);
    const { observation, nfeId } = data;
    await correctionNfe({
      id: nfeId,
      body: { idBasket: nfeId, justification: observation },
    })
      .then(() => {
        setRequestStatus(true);
        setShowModalCorrection(false);
        setRequestStatus(undefined);
        toast.success("Carta de correção enviada com sucesso.");
        window.location.reload();
      })
      .catch((err) => {
        setLoadingBackdrop(false);
        setRequestStatus(false);

        if (err.response && Array.isArray(err.response?.data?.errors)) {
          err.response.data.errors.forEach((e) =>
            setBackendErrors({ [e.fieldName]: e.message })
          );
        }
      });
    setLoadingBackdrop(false);
  };

  const handleInvoiceCancelSubmit = async ({ data }) => {
    setLoadingBackdrop(true);
    const { observation, nfeId } = data;

    await cancelNFe({
      id: nfeId,
      body: { idBasket: nfeId, justification: observation },
    })
      .then(() => {
        setRequestStatus(true);
        setShowModalCancellation(false);
        setRequestStatus(undefined);
        toast.success("Nota fiscal cancelada com sucesso.");
        window.location.reload();
      })
      .catch((err) => {
        setLoadingBackdrop(false);
        setRequestStatus(false);

        if (err.response && Array.isArray(err.response?.data?.errors)) {
          err.response.data.errors.forEach((e) =>
            setBackendErrors({ [e.fieldName]: e.message })
          );
        }
      });
    setLoadingBackdrop(false);
  };
  const DownloadXML = (xmlString,chaveDfe) => {
        const blob = new Blob([xmlString], { type: 'text/xml' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = chaveDfe + '.xml';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
  
  }

  const downloadNfe = async (nota) => {
    console.log(nota);
    setLoading(true);
    await consultaNfe(nota).then(
      (response) => {
        console.log(response);
        if(response.data.status == "200") {
          window.open(
            response.data.file,
            "_blank",
            "noopener,noreferrer"
          );
        } else {
          NotificationManager.error("Erro ao buscar NFE");
        }
        setLoading(false);
      }).catch((error) => {
        NotificationManager.error("Erro ao buscar NFE");
      })
  }
  function generateMotive(service) {
    // console.log("service");
    // console.log(service);
    switch (service.typeInvoice) {
      case typeInvoiceEnum.DEVOLUCAO:
        if (service.codStatus == 135 || service.codStatus == 100) {
          return "Devolução da nota fiscal";
        } else {
          return "Falha ao processar devolução da nota fiscal";
        }
        break;

      case typeInvoiceEnum.CANCELAMENTO:
        if (service.codStatus == 135) {
          return "Nota fiscal cancelada";
        } else {
          return "Falha ao processar cancelamento nota fiscal";
        }
        break;

      case typeInvoiceEnum.CORRECAO:
        if (service.codStatus == 135) {
          return "Carta de correção";
        } else {
          return "Falha ao processar carta de correção";
        }

      case typeInvoiceEnum.NORMAL:
        if (service.codStatus == 100) {
          return service.motivo;
        } else {
          return "Falha ao emitir nota fiscal";
        }

      default:
        return service.motivo;
    }
  }
  const confirmationModal = (
    <DialogAutoOpen
      dialogData={{
        size: "lg",
        body: (
          <div className="row col-12 col-md-12 m-0 p-0">
            <div className="col-sm-12">
              <p className="text-muted">
                {" "}
                Esta ação é irreversível! Deseja realmente cancelar a nota
                fiscal?
              </p>
            </div>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={schema}
              initialValues={{ observation: undefined, nfeId }}
              onSubmit={(data) => handleInvoiceCancelSubmit({ data })}
              children={(field) => (
                <Form className="row col-12 m-0 p-0 justify-content-sm-center justify-content-md-start">
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <p className="m-0">
                        <span className="text-danger">*</span>
                        Motivo do Cancelamento
                      </p>
                      <div className="input-group">
                        <span
                          style={{ height: 150 }}
                          className="input-group-prepend input-group-text bg-white"
                        >
                          <FontAwesomeIcon
                            color="#64c2c8"
                            icon={faClipboardList}
                          />
                        </span>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Field
                            style={{ width: 600, height: 150 }}
                            as="textarea"
                            rows="4"
                            name="observation"
                            maxLength="255"
                            className="form-control"
                            placeholder="Informe o motivo do cancelamento"
                          />
                          {console.log(field)}
                          <span
                            style={{
                              fontSize: 12,
                              justifyContent: "flex-end",
                              marginTop: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            Máximo de Caracteres: (
                            {field.values && field.values.observation
                              ? field.values.observation.length
                              : "0"}
                            /255)
                          </span>
                        </div>
                      </div>
                      <ErrorMessage
                        className="text-danger col-12 p-0"
                        component="small"
                        name="observation"
                      />
                    </div>
                    <div className="form-row justify-content-center">
                      <div className="col-sm-4">
                        <ButtonSubmit
                          buttonData={{
                            variant: "outline-info",
                            text: "Sim",
                            _class: "font-weight-bold col mr-1 mt-2",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <ButtonActionDefault
                          className="createAccout_btn font-weight-bold col mr-1 mt-2"
                          buttonData={{
                            variant: "info",
                            text: "Não",
                            _class: `font-weight-bold col mr-1 mt-2`,
                            action: () => {
                              setShowModalCancellation(false);
                              setRequestStatus(undefined);
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      {handleStatus(requestStatus)}
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        ),
      }}
    />
  );

  const confirmationModalCorrection = (
    <DialogAutoOpen
      dialogData={{
        size: "lg",
        body: (
          <div className="row col-12 col-md-12 m-0 p-0">
            <div className="col-sm-12">
              <p className="text-muted">
                {" "}
                Esta ação é irreversível! Deseja realmente corrigir a nota
                fiscal?
              </p>
            </div>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={schemaCorrection}
              initialValues={{ observation: undefined, nfeId }}
              onSubmit={(data) => handleInvoiceCorrectionSubmit({ data })}
              children={(field) => (
                <Form className="row col-12 m-0 p-0 justify-content-sm-center justify-content-md-start">
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <p className="m-0">
                        <span className="text-danger">*</span>
                        Justificativa para correção
                      </p>
                      <div className="input-group">
                        <span
                          style={{ height: 150 }}
                          className="input-group-prepend input-group-text bg-white"
                        >
                          <FontAwesomeIcon
                            color="#64c2c8"
                            icon={faClipboardList}
                          />
                        </span>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Field
                            style={{ width: 600, height: 150 }}
                            as="textarea"
                            rows="4"
                            name="observation"
                            maxLength="1000"
                            className="form-control"
                            placeholder="Informe os dados a serem corrigidos e o motivo."
                          />
                          {console.log(field)}
                          <div style={{ display: "flex" }}>
                            <span
                              style={{
                                fontSize: 12,
                                justifyContent: "flex-end",
                                marginTop: "auto",
                                marginLeft: "auto",
                              }}
                            >
                              Máximo de Caracteres: (
                              {field.values && field.values.observation
                                ? field.values.observation.length
                                : "0"}
                              /1000)
                            </span>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        className="text-danger col-12 p-0"
                        component="small"
                        name="observation"
                      />
                    </div>
                    <div className="form-row justify-content-center">
                      <div className="col-sm-4">
                        <ButtonSubmit
                          buttonData={{
                            variant: "outline-info",
                            text: "Sim",
                            _class: "font-weight-bold col mr-1 mt-2",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <ButtonActionDefault
                          className="createAccout_btn font-weight-bold col mr-1 mt-2"
                          buttonData={{
                            variant: "info",
                            text: "Não",
                            _class: `font-weight-bold col mr-1 mt-2`,
                            action: () => {
                              setShowModalCorrection(false);
                              setRequestStatus(undefined);
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      {handleStatus(requestStatus)}
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        ),
      }}
    />
  );

  const confirmationModalJustification = (
    <DialogAutoOpen
      dialogData={{
        size: "lg",
        body: (
          <div className="row col-12 col-md-12 m-0 p-0">
            <div className="col-sm-12">
              <p className="text-muted">
                {" "}
                Esta ação é irreversível! Deseja realmente realizar a devolução
                da nota fiscal?
              </p>
            </div>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              initialValues={{ observation: undefined, nfeId }}
              onSubmit={(data) => handleInvoiceReturnSubmit({ data })}
              children={(field) => (
                <Form className="row col-12 m-0 p-0 justify-content-sm-center justify-content-md-start">
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <ErrorMessage
                        className="text-danger col-12 p-0"
                        component="small"
                        name="observation"
                      />
                    </div>
                    <div className="form-row justify-content-center">
                      <div className="col-sm-4">
                        <ButtonSubmit
                          buttonData={{
                            variant: "outline-info",
                            text: "Sim",
                            _class: "font-weight-bold col mr-1 mt-2",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <ButtonActionDefault
                          className="createAccout_btn font-weight-bold col mr-1 mt-2"
                          buttonData={{
                            variant: "info",
                            text: "Não",
                            _class: `font-weight-bold col mr-1 mt-2`,
                            action: () => {
                              setShowModalJustification(false);
                              setRequestStatus(undefined);
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      {handleStatus(requestStatus)}
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        ),
      }}
    />
  );

  const page = (
    <>
      <div
        className="mt-5"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {/* <div className="row ml-lg-3">
          <ButtonMaterial
            onClick={handleClickOpen}
            variant="contained"
            sx={{
              marginBottom: 2,
              backgroundColor: "#D70F57",
              fontColor: "#FFF",
              "&:hover": {
                backgroundColor: "#D70F57",
              },
            }}
            endIcon={<ErrorOutlineIcon />}
          >
            Procedimento Importante{" "}
          </ButtonMaterial>
        </div>

        <div className="alert alert-danger mt-4" style={{ width: "72vw" }}>
          Para emissão de nota fiscal é necessário possuir certificado digital,
          caso deseje contratar{" "}
          <a
            href="https://acsp.com.br/lp-certificado-home"
            className="alert-link"
            target="_blank"
          >
            clique aqui
          </a>{" "}
          e contrate de forma rápida e fácil.
        </div> */}

        {limitNfIssuedReached && planoAtivo ? (
          // <div style={{ width: "72vw" }}>
          //   <div
          //     className="alert alert-warning"
          //     style={{
          //       width: "488px",
          //       backgroundColor: "#FFCC4A",
          //       color: "#FFF",
          //     }}
          //   >
          //     <ErrorOutlineIcon />
          //     <span style={{ paddingLeft: "10px", fontWeight: "700" }}>
          //       O número máximo de emissão de Notas Fiscais foi atingido
          //     </span>
          //   </div>
          // </div>
          <div style={{ width: "72vw" }}>
            <div
              className=""
              style={{
                width: "601px",
                height: "44px",
                borderRadius: "5px",
                backgroundColor: "#D70F57",
                color: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: "-10px" }}>
                <ErrorOutlineIcon />
              </div>
              <span
                style={{
                  paddingLeft: "10px",
                  lineHeight: "18px",
                  fontSize: "14px",
                }}
              >
                O número máximo de emissão de Notas Fiscais foi atingido.
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        {!planoAtivo ? (
          <div style={{ width: "72vw" }}>
            <div
              className=""
              style={{
                width: "601px",
                height: "44px",
                borderRadius: "5px",
                backgroundColor: "#D70F57",
                color: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: "-10px" }}>
                <ErrorOutlineIcon />
              </div>
              <span
                style={{
                  paddingLeft: "10px",
                  lineHeight: "18px",
                  fontSize: "14px",
                }}
              >
               Seu plano junto a plataforma venceu, favor acionar um de nossos <br></br>especialistas para continuar emitindo notas fiscais.
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div
          className="mt-5"
          style={{
            width: "72vw",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {showModalCancellation && confirmationModal}
          {showModalJustification && confirmationModalJustification}
          {showModalCorrection && confirmationModalCorrection}
          <div
            style={{
              display: "flex",
            }}
          >
            <h2
              onClick={() => {
                history.push("/home");
              }}
            >
              <FontAwesomeIcon
                className="back-icon labelmark--backarrow mr-3"
                icon={faArrowLeft}
                size="1x"
                color="#002c43"
              />
            </h2>

            <h2 style={{ color: "#002c43" }}>Notas Fiscais de produto </h2>
          </div>
          <div className="">
            {limitNfIssuedReached || !planoAtivo ? (
              <>
                <Link to="invoice_product/create">
                  <Button
                    disabled
                    style={{
                      fontWeight: "600",
                      backgroundColor: "#7B7B7B",
                      border: "none",
                    }}
                  >
                    <span style={{ marginRight: "8px" }}>
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                    Nova nota fiscal
                  </Button>
                </Link>
              </>
            ) : (
              <Link to="invoice_product/create">
                <Button variant="info" style={{ fontWeight: "600" }}>
                  <span style={{ marginRight: "8px" }}>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                  Nova nota fiscal
                </Button>
              </Link>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div
            className=""
            style={{
              width: "72vw",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <MUIDataTable
              title={"Notas Fiscais de produto"}
              data={invoiceProduct.map((service, index) => {
                const isShowCancelButton =
                  moment().diff(moment(service.issuedAt), "hours") > 168;
                return [
                  <p>{service.id}</p>,
                  <p data-id={service.id}>{service?.productInvoiceSerie?.serie}</p>,
                  <p>{service?.numeroNfe}</p>,
                  <p>{moment(service?.createdAt).format("DD/MM/yyyy")}</p>,
                  <p>{service?.associatedClient?.socialName}</p>,
                  <p>
                    R$ {Currency({ value: parseFloat(service.totalValue) })}
                  </p>,
                  <Tooltip
                    disableHoverListener={
                      service?.codStatus === 100 || service.codStatus === 135
                        ? true
                        : false
                    }
                    // title={`${service.motivo}`}
                  >
                    <p>{generateMotive(service)}</p>
                  </Tooltip>,
                  <div>
                    <IconButton
                      onClick={(event) => handleClick(event, service.id)}
                    >
                      <MoreHorizIcon />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl[service.id]}
                      open={Boolean(anchorEl[service.id])}
                      keepMounted={true}
                      onClose={(event) => handleClose(event, service.id)}
                    >
                      {service.codStatus === "Error" && (
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          color="#878787"
                          style={{ cursor: "pointer" }}
                          size="lg"
                          title={service.providerError}
                          onClick={() => {
                            // history.push(`/financial/invoice_product/view/${service.id}`)
                          }}
                        />
                      )}

                      {/* <FontAwesomeIcon
                    icon={faEye}
                    color="#878787"
                    style={{ cursor: 'pointer' }}
                    size="lg"
                    title="Visualizar os dados da Nota Fiscal de Produto"
                    onClick={() => {
                      history.push(`/financial/invoice_product/view/${service.id}`)
                    }}
                  /> */}
                      <MenuItem
                        key={index + 1000}
                        onClick={() => {
                          history.push(
                            `/financial/invoice_product/view/${service.id}`
                          );
                        }}
                      >
                        Visualizar
                      </MenuItem>
{/*
                      {service.reportZip && (
                        <MenuItem
                          key={index + 1001}
                          onClick={() => {
                            window.open(
                              service.reportZip,
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          Baixar Nota Fiscal
                        </MenuItem>
                      )}
*/}
                      {service.reportZip && (
                        <MenuItem
                          key={index + 1001}
                          onClick={() => {
                            downloadNfe(service)
                          }}
                        >
                           {loading ? (
                              <span>
                                Baixando a nota
                                <span className="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                              </span>
                            ) : (
                              'Baixar Nota Fiscal'
                            )}
                        </MenuItem>
                      )}
                      {service.xmlDfe && (
                        <MenuItem
                          key={index + 1001}
                          onClick={() => {
                            DownloadXML(
                              service.xmlDfe, service.chaveDfe
                            );
                          }}
                        >
                          Baixar Xml da Nota Fiscal
                        </MenuItem>
                      )}
                      {service.codStatus === 100 &&
                        !isShowCancelButton &&
                        (service.cancelada === false ||
                          service.cancelada === null) &&
                        (service.returned === false ||
                          service.returned == null) &&
                        service.typeInvoice === typeInvoiceEnum.NORMAL && (
                          <MenuItem
                            key={index + 1002}
                            onClick={() => showCancelationModal(service.id)}
                          >
                            {" "}
                            Cancelar Nota Fiscal
                          </MenuItem>
                        )}

                      {service.codStatus === 100 &&
                        (service.correction === false ||
                          service.correction === null) &&
                        (service.returned === false ||
                          service.returned == null) &&
                        (service.cancelada === false ||
                          service.cancelada === null) &&
                        service.typeInvoice === typeInvoiceEnum.NORMAL && (
                          <MenuItem
                            key={index + 1003}
                            onClick={() => showJustificationModal(service.id)}
                          >
                            {" "}
                            Devolver Nota Fiscal
                          </MenuItem>
                        )}

                      {service.codStatus === 100 &&
                        (service.correction === false ||
                          service.correction === null) &&
                        (service.returned === false ||
                          service.returned == null) &&
                        (service.cancelada === false ||
                          service.cancelada === null) &&
                        service.typeInvoice === typeInvoiceEnum.NORMAL && (
                          <MenuItem
                            key={index + 1004}
                            onClick={() => showCorrectionModal(service.id)}
                          >
                            {" "}
                            Solicitar Carta de Correção
                          </MenuItem>
                        )}
                    </Menu>
                  </div>,
                ];
              })}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div>
      <PageDefault
        shouldNotScroll={true}
        pageData={{
          pageContent: page,
        }}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>{" "}
      <ModalWarningUpdate handleClose={handleCloseModal} open={openModal} />
    </div>
  );
}

export default InvoiceProduct;
