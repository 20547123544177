import styled from "styled-components";

export const Input = styled.input`
  color: #aeaeae !important;
  background-color: #fff !important;
  border: 1px solid #aeaeae !important;
  border-radius: 5px !important;
`;

export const InputTextArea = styled.textarea`
  color: #aeaeae !important;
  background-color: #fff !important;
  border: 1px solid #aeaeae !important;
  border-radius: 5px !important;
  resize: none;
  width: 500px;
  text-indent: 10px;
  padding-top: 10px;
  height: 220px;
`;

export const DivFormGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const DivFormInput = styled.div`
  width: 50%;
`;

export const ButtonCadastro = styled.button`
  width: 90%;
  background: #ffb827;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`;

// export const DivLogin = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
// `;

export const BtnDivLogin = styled.button`
  margin-top: 17px;
  height: 43px;
  color: #ffffff;
  background-color: #002c43;
  width: 79%;
  border-radius: 5px;
  font-size: 24px;
  font-family: Source Sans Pro;
  font-weight: 600;

  &:hover {
    background-color: #fff;
    color: #002c43;
    border: 1px solid #002c43;
  }

  @media screen and (min-width: 901px) and (max-width: 1367px) {
    font-size: 20px !important;
  }

  @media screen and (min-width: 1367px) and (max-width: 1537px) {
    font-size: 20px !important;
  }
`;

export const DivLoginText = styled.span`
  font-family: "Source Sans Pro" !important;
  font-weight: 900 !important;
  font-size: 24px;
  margin-top: 15px;
  margin-left: 20%;
  cursor: pointer;
  color: #002c43;

  @media screen and (max-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    font-size: 16px;

    h3 {
      font-size: 24px;
      font-family: "Source Sans Pro" !important;
      font-weight: 900 !important;
      /* margin-left: -20px !important; */
    }

    .icon-menu-landing-page {
      margin-right: 6px !important;
    }
  }

  @media screen and (min-width: 901px) and (max-width: 1367px) {
    font-size: 20px;
    margin-left: 15%;
  }

  @media screen and (min-width: 1367px) and (max-width: 1537px) {
    font-size: 20px;
    margin-left: 18%;
  }
`;

export const DivPrincipal = styled.div`
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const DivMobile = styled.div`
  background-color: #002c43;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    width: 188px;
  }

  .iconHamburger,
  .iconClosedHamburger {
    color: #fff;
    cursor: pointer;
    font-size: 24px;
    display: ${(props) => (props.isMenuOpen ? "none" : "block")};
  }

  .iconClosedHamburger {
    color: #fff;
    display: ${(props) => (props.isMenuOpen ? "block" : "none")};
  }

  .logo-menu-landing-page {
    cursor: pointer;
  }

  /* Estilos que aparecerão ao abrir o menu */
  .menu-items {
    display: ${(props) => (props.isMenuOpen ? "block" : "none")};
    background-color: #002c43;
    padding: 10px;
    position: absolute;
    top: 64px;
    width: 100%;
    z-index: 10;
    text-align: center;
  }

  .menu-items a {
    display: block;
    color: #fff;
    font-weight: 700;
    padding: 5px;
    cursor: pointer;
    text-decoration: none;
  }

  @media screen and (min-width: 901px) {
    display: none;
  }
`;

export const WrapperIconHamburger = styled.div`
  margin-left: 5%;
`;

export const WrapperLogoMenuLandingPage = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const CenterLoginHamburger = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const WrapperLoginHamburger = styled.div`
  width: 343px;
  height: 64px;
  background: #58c5c6;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
