import styled from "styled-components";

export const ContainerFooter = styled.div`
  width: 100%;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const ContainerDiv1 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
  text-align: center;

  span {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 90%;
  }

  h2 {
    font-size: 20px;
    font-family: Source Sans Pro;
    font-weight: 700;
    line-height: 24px;
    color: #002c43;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-family: Source Sans Pro;
    font-weight: 600;
    line-height: 17px;
    color: #7b7b7b;
  }
`;

export const ContainerDiv2 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  text-align: center;

  span {
    display: flex;
    flex-direction: column;
    width: 90%;
    font-family: Source Sans Pro;
    font-size: 20px;
  }

  h2 {
    font-size: 20px;
    font-weight: 700;
    font-family: Source Sans Pro;
    color: #002c43;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    font-family: Source Sans Pro;
    color: #7b7b7b;
    line-height: 17px;
  }
`;

export const ContainerDiv2Icons = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;

  img {
    width: 32px;
    height: 32px;
  }

  p {
    font-weight: 700;
    font-size: 20px;
    padding: 7px 37px 0px 12px;
  }
`;

export const ContainerDiv3 = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px 0px 40px 0px;

  span {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  h2 {
    font-size: 20px;
    font-family: Source Sans Pro;
    font-weight: 700;
    color: #002c43;
  }
`;

export const ContainerDiv3Icons = styled.div`
  display: flex;

  img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
`;

export const HeaderLoginMobile = styled.div`
  width: 100%;
  height: 64px;
  background-color: #002c43;
  display: flex;
  justify-content: center;

  img {
    width: 188px;
  }
`;
