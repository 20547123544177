import React, { useState } from "react";
import * as Yup from "yup";
import { postLogin } from "../../../../services/associate";
import { Formik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import * as S from "./styles";
import logo from "../../../../imgs/logo/LogoMeiACSP.svg";
import "./css/styleLogin.css";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

export default function HeaderLogin({ disableLogin }) {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [wrapperLoginHeight, setWrapperLoginHeight] = useState(64);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [openLoginBox, setOpenLoginBox] = useState(false);
  const [isLoadingLogin, setLoadingLogin] = useState(false);
  const location = useLocation();
  const [backErrors, setBackErrors] = useState({});
  const history = useHistory("");
  const [loginCnpjCode, setLoginCnpjCode] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const loginSchema = Yup.object().shape({
    loginCnpjCode: Yup.string().nullable().required("Campo Obrigatório"),
    loginPassword: Yup.string().nullable().required("Campo Obrigatório"),
  });

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const submitLoggin = async (values) => {
    setLoadingLogin(true);
    setBackErrors({});
    const data = {
      username: values.loginCnpjCode,
      password: values.loginPassword,
    };

    try {
      const response = await postLogin(data);
      const token = response.headers["x-authorization"];
      setLoadingLogin(false);
      if (token) {
        localStorage.setItem("jwt_auth", token);
        history.push("/home");
      }

      return true;
    } catch (err) {
      setLoadingLogin(false);
      const errors = {};
      err.response?.data?.errors?.forEach(
        ({ fieldName, message }) => (errors[fieldName] = message)
      );
      setBackErrors(errors);
    }
  };

  return (
    <>
      <S.DivPrincipal>
        <Grid
          alignItems="center"
          sx={{ backgroundColor: "#002C43", height: 150 }}
          container
          spacing={3}
          marginBottom={-4}
        >
          <Grid marginTop={-1} item xs={2.5} style={{ marginLeft: "10%" }}>
            <Link
              activeClass="active"
              className="clickPointer"
              to="home"
              spy={true}
              smooth={true}
              duration={500}
            >
              <img
                className="logo-menu-landing-page"
                src={logo}
                alt="logo-acsp"
              />
            </Link>
          </Grid>
          <Grid container alignItems="center" gridRow={true} xs={6.9}>
            <Grid item xs={2} marginTop={2}>
              <Link
                onClick={() => history.push("/")}
                style={{
                  color: "#FFFFFF",
                  fontWeight: 700,
                  fontFamily: "Source Sans Pro",
                }}
                activeClass="active"
                className="clickPointer link-menu-landing-page link-menu-landing-page-acsp "
                to="sct-sobre"
                spy={true}
                smooth={true}
                duration={500}
              >
                A ACSP
              </Link>
            </Grid>
            <Grid item xs={2} marginTop={2}>
              <Link
                onClick={() => history.push("/")}
                activeClass="active"
                style={{
                  color: "#FFFFFF",
                  fontWeight: 700,
                  marginLeft: "-20px",
                  fontFamily: "Source Sans Pro",
                }}
                className="clickPointer link-menu-landing-page link-menu-landing-page-vantagens"
                to="sct2"
                spy={true}
                smooth={true}
                duration={500}
              >
                VANTAGENS
              </Link>
            </Grid>
            <Grid item xs={2} marginTop={2}>
              <a
                className="clickPointer link-menu-landing-page link-menu-landing-page-querosermei"
                style={{
                  color: "#FFB827",
                  fontWeight: 700,
                  fontFamily: "Source Sans Pro",
                }}
                onClick={() => {
                  history.push("login");
                }}
              >
                JÁ SOU MEI
              </a>
            </Grid>
            <Grid item xs={2} marginTop={2}>
              <Link
                onClick={() => history.push("/")}
                activeClass="active"
                style={{
                  color: "#58C5C6",
                  fontWeight: 700,
                  fontFamily: "Source Sans Pro",
                }}
                className="clickPointer link-menu-landing-page link-menu-landing-page-jasoumei"
                to="sct-formularios"
                spy={true}
                smooth={true}
                duration={500}
              >
                QUERO SER MEI
              </Link>
            </Grid>

            {!disableLogin ? (
              openLoginBox ? (
                <Grid
                  className="container-divLogin"
                  item
                  sx={{
                    transform: "translate(0%, 35%)",
                    height: 400,
                    width: "17.2%",
                    background: "#58C5C6",
                    borderRadius: "0px 0px 10px 10px",
                    zIndex: 1,
                    position: "absolute",
                    right: "14%",
                    paddingTop: "10px",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <div style={{ marginTop: 45 }}>
                    <S.DivLoginText>ACESSE O PORTAL</S.DivLoginText>
                    <div
                      style={{
                        width: "100%",
                        marginLeft: "11%",
                        marginTop: 15,
                      }}
                    >
                      <Formik
                        enableReinitialize
                        validateOnBlur={false}
                        validateOnChange={false}
                        initialValues={(loginCnpjCode, loginPassword)}
                        onSubmit={async (values, { setSubmitting }) => {
                          setSubmitting(false);
                          await submitLoggin({ loginCnpjCode, loginPassword });
                          setSubmitting(true);
                        }}
                      >
                        {({ values, handleSubmit }) => (
                          <form>
                            <TextField
                              InputLabelProps={{
                                shrink: false,
                                style: { color: "#7b7b7b" },
                              }}
                              label={
                                loginCnpjCode.length > 0
                                  ? ""
                                  : "CNPJ ou Código de Associado"
                              }
                              onChange={(event) => {
                                setLoginCnpjCode(event.target.value);
                              }}
                              name="loginCnpjCode"
                              inputProps={{ style: { color: "#7b7b7b" } }}
                              sx={{
                                background: "#FFFFFF",
                                borderRadius: 1,
                                marginBottom: 1,
                                width: "79%",
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#58C5C6",
                                  },
                                  "&::placeholder": {
                                    color: "#58C5C6",
                                  },
                                },
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#64c2c8",
                                },
                              }}
                            />

                            <TextField
                              type="password"
                              InputProps={{
                                disableUnderline: true,
                              }}
                              name="loginPassword"
                              variant="outlined"
                              label={loginPassword.length > 0 ? "" : "Senha"}
                              InputLabelProps={{
                                shrink: false,
                                style: { color: "#7b7b7b" },
                              }}
                              inputProps={{ style: { color: "#7b7b7b" } }}
                              onChange={(event) => {
                                setLoginPassword(event.target.value);
                              }}
                              sx={{
                                marginTop: "3px",
                                color: "#AEAEAE",
                                background: "#FFFFFF",
                                borderRadius: "0px 0px 10px 10px",
                                borderRadius: 1,
                                width: "79%",
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: "#58C5C6",
                                  },
                                  "&::placeholder": {
                                    color: "#58C5C6",
                                  },
                                },
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#64c2c8",
                                },
                              }}
                              value={loginPassword}
                            />
                            {backErrors.auth && (
                              <div className="form-goup">
                                <small className="text-danger text-right text-small">
                                  <b>{backErrors.auth}</b>
                                </small>
                              </div>
                            )}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <S.BtnDivLogin onClick={handleSubmit}>
                                Entrar
                              </S.BtnDivLogin>
                              <a
                                style={{
                                  alignSelf: "center",
                                  marginLeft: "-20%",
                                  textDecorationLine: "underline",
                                  marginTop: 15,
                                  fontWeight: 700,
                                  cursor: "Pointer",
                                  color: "#002C43",
                                  fontFamily: "Source Sans Pro",
                                  fontSize: "14px",
                                }}
                                onClick={() => {
                                  history.push("profile/recovery");
                                }}
                              >
                                Esqueci minha senha
                              </a>
                              <button
                                disabled={
                                  location.pathname == "/login" ? true : false
                                }
                                style={{
                                  alignSelf: "center",
                                  marginLeft: "-20%",
                                  textDecorationLine: "underline",
                                  marginTop: 15,
                                  fontWeight: 700,
                                  cursor: "Pointer",
                                  color: "#002C43",
                                  fontFamily: "Source Sans Pro",
                                  fontsize: "16px",
                                }}
                                onClick={() => {
                                  history.push("/login");
                                }}
                              >
                                Criar uma conta
                              </button>
                            </div>
                          </form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  xs={3.5}
                  container
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    height: 135,
                    background: "#58C5C6",
                    borderRadius: "0px 0px 10px 10px",
                  }}
                >
                  <S.DivLoginText onClick={() => setOpenLoginBox(true)}>
                    <KeyboardTabIcon className="icon-menu-landing-page" />
                    Olá! Faça seu Login.
                  </S.DivLoginText>
                </Grid>
              )
            ) : null}
          </Grid>
        </Grid>
      </S.DivPrincipal>

      {/* menú hamburger início */}
      <S.DivMobile isMenuOpen={isMenuOpen}>
        <S.WrapperIconHamburger onClick={toggleMenu}>
          <MenuRoundedIcon className="iconHamburger" />
          <CloseRoundedIcon className="iconClosedHamburger" />
        </S.WrapperIconHamburger>
        <S.WrapperLogoMenuLandingPage>
          <a onClick={() => history.push("/")}>
            <img src={logo} alt="logo-acsp" />
          </a>
        </S.WrapperLogoMenuLandingPage>
        {isMenuOpen && (
          <div className="menu-items">
            {!disableLogin ? (
              openLoginBox ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "343px",
                      height: "400px",
                      background: "#58C5C6",
                      borderRadius: "10px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid
                      item
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "343px",
                        background: "#58C5C6",
                        zIndex: 1,
                        position: "absolute",
                        paddingTop: "10px",
                      }}
                    >
                      <div>
                        <S.DivLoginText>
                          <h3>ACESSE O PORTAL</h3>
                        </S.DivLoginText>
                        <div
                          style={{
                            width: "100%",
                            marginTop: 15,
                          }}
                        >
                          <Formik
                            enableReinitialize
                            validateOnBlur={false}
                            validateOnChange={false}
                            initialValues={(loginCnpjCode, loginPassword)}
                            onSubmit={async (values, { setSubmitting }) => {
                              setSubmitting(false);
                              await submitLoggin({
                                loginCnpjCode,
                                loginPassword,
                              });
                              setSubmitting(true);
                            }}
                          >
                            {({ values, handleSubmit }) => (
                              <form>
                                <TextField
                                  InputLabelProps={{
                                    shrink: false,
                                    style: { color: "#7b7b7b" },
                                  }}
                                  label={
                                    loginCnpjCode.length > 0
                                      ? ""
                                      : "CNPJ ou Código de Associado"
                                  }
                                  onChange={(event) => {
                                    setLoginCnpjCode(event.target.value);
                                  }}
                                  name="loginCnpjCode"
                                  inputProps={{ style: { color: "#7b7b7b" } }}
                                  sx={{
                                    background: "#FFFFFF",
                                    borderRadius: 1,
                                    marginBottom: 1,
                                    width: "79%",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#58C5C6",
                                      },
                                      "&::placeholder": {
                                        color: "#58C5C6",
                                      },
                                    },
                                    "&:hover": {
                                      backgroundColor: "#fff",
                                      color: "#64c2c8",
                                    },
                                  }}
                                />

                                <TextField
                                  type="password"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  name="loginPassword"
                                  variant="outlined"
                                  label={
                                    loginPassword.length > 0 ? "" : "Senha"
                                  }
                                  InputLabelProps={{
                                    shrink: false,
                                    style: { color: "#7b7b7b" },
                                  }}
                                  inputProps={{ style: { color: "#7b7b7b" } }}
                                  onChange={(event) => {
                                    setLoginPassword(event.target.value);
                                  }}
                                  sx={{
                                    marginTop: "3px",
                                    color: "#AEAEAE",
                                    background: "#FFFFFF",
                                    borderRadius: "0px 0px 10px 10px",
                                    borderRadius: 1,
                                    width: "79%",
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#58C5C6",
                                      },
                                      "&::placeholder": {
                                        color: "#58C5C6",
                                      },
                                    },
                                    "&:hover": {
                                      backgroundColor: "#fff",
                                      color: "#64c2c8",
                                    },
                                  }}
                                  value={loginPassword}
                                />
                                {backErrors.auth && (
                                  <div className="form-goup">
                                    <small className="text-danger text-right text-small">
                                      <b>{backErrors.auth}</b>
                                    </small>
                                  </div>
                                )}

                                <S.BtnDivLogin onClick={handleSubmit}>
                                  Entrar
                                </S.BtnDivLogin>

                                <a
                                  style={{
                                    alignSelf: "center",
                                    textDecorationLine: "underline",
                                    marginTop: 15,
                                    fontWeight: 700,
                                    cursor: "Pointer",
                                    color: "#002C43",
                                    fontFamily: "Source Sans Pro",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => {
                                    history.push("profile/recovery");
                                  }}
                                >
                                  Esqueci minha senha
                                </a>
                                <button
                                  disabled={
                                    location.pathname == "/login" ? true : false
                                  }
                                  style={{
                                    alignSelf: "center",
                                    textDecorationLine: "underline",
                                    marginTop: 15,
                                    fontWeight: 700,
                                    cursor: "Pointer",
                                    color: "#002C43",
                                    fontFamily: "Source Sans Pro",
                                    fontsize: "16px",
                                  }}
                                  onClick={() => {
                                    history.push("/login");
                                  }}
                                >
                                  Criar uma conta
                                </button>
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Grid>
                  </div>
                </div>
              ) : (
                <S.CenterLoginHamburger>
                  <S.WrapperLoginHamburger
                    onClick={() => setOpenLoginBox(true)}
                  >
                    <S.DivLoginText>
                      <KeyboardTabIcon className="icon-menu-landing-page" />
                      Olá! Faça seu Login.
                    </S.DivLoginText>
                  </S.WrapperLoginHamburger>
                </S.CenterLoginHamburger>
              )
            ) : null}

            {/* menú hamburger fim */}
            <Link
              onClick={() => history.push("/")}
              style={{
                color: "#FFFFFF",
                fontWeight: 700,
                fontFamily: "Source Sans Pro",
              }}
              activeClass="active"
              className="link-menu-landing-page link-menu-landing-page-acsp"
              to="sct-sobre"
              spy={true}
              smooth={true}
              duration={500}
            >
              A ACSP
            </Link>
            <Link
              className="link-menu-landing-page"
              onClick={() => history.push("/")}
              activeClass="active"
              style={{
                color: "#FFFFFF",
                fontWeight: 700,
                fontFamily: "Source Sans Pro",
              }}
              to="sct2"
              spy={true}
              smooth={true}
              duration={500}
            >
              VANTAGENS
            </Link>

            <a
              className="link-menu-landing-page"
              style={{
                color: "#FFB827",
                fontWeight: 700,
                fontFamily: "Source Sans Pro",
              }}
              onClick={() => {
                history.push("login");
              }}
            >
              JÁ SOU MEI
            </a>
            <Link
              onClick={() => history.push("/")}
              activeClass="active"
              style={{
                color: "#58C5C6",
                fontWeight: 700,
                fontFamily: "Source Sans Pro",
              }}
              className=" link-menu-landing-page "
              to="sct-formularios"
              spy={true}
              smooth={true}
              duration={500}
            >
              QUERO SER MEI
            </Link>
          </div>
        )}
      </S.DivMobile>

      <Backdrop open={isLoadingLogin} style={{ zIndex: 100 }}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
}
