import "bootstrap/dist/css/bootstrap.min.css";

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from "react-helmet-async";

import "./index.css";
import Home from "./modules/home";
import Login from "./modules/login";
import AnnualDeclaration from "./modules/financial/pages/AnnualDeclaration";
import AnnualDeclarationCreate from "./modules/financial/pages/AnnualDeclarationCreate";
import InvoiceDevolution from "./modules/financial/pages/InvoiceDevolution";
import InvoiceProduct from "./modules/financial/pages/InvoiceProduct";
import InvoiceSeries from "./modules/financial/pages/InvoiceSeries";
import InvoiceService from "./modules/financial/pages/InvoiceService";
import PayTax from "./modules/financial/pages/PayTax";

import CompanyData from "./modules/profile/pages/CompanyData";
import EditProfile from "./modules/profile/pages/EditProfile";
import SaveCertificate from "./modules/profile/pages/SaveCertificate";
import Recovery from "./modules/profile/pages/Recovery";
import Reset from "./modules/profile/pages/Reset";
import ChangeEmail from "./modules/profile/pages/ChangeEmail";

import Product from "./modules/registration/pages/Product";
import Service from "./modules/registration/pages/Service";
import ServicePage from "./modules/registration/pages/ServicePage";
import Client from "./modules/registration/pages/Client";
import Provider from "./modules/registration/pages/Provider";
import ProviderCreate from "./modules/registration/pages/ProviderCreate";
import ProviderEdit from "./modules/registration/pages/ProviderEdit";
import ProviderView from "./modules/registration/pages/ProviderView";

import Training from "./modules/training";
import Consultancy from "./modules/consultancy";
import ConsultancyCreate from "./modules/consultancy/pages/ConsultancyCreate";

import ProfileMenu from "./modules/profileMenu";

import Services from "./modules/service";

import LegalInformation from "./modules/information";
import UseTerms from "./modules/information/pages/UseTerms";

import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Switch, Route } from "react-router-dom";


import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {TourProvider} from '@reactour/tour';
import { NotificationContainer } from "react-notifications";

import {stepsLogin} from './utils/stepsTour';

import Loading from "../src/global/Loading";


const ResendActivateToken =  lazy(() => import("./modules/profile/pages/ResendActivateToken"));
const TrainingDetail =  lazy(() => import("./modules/training/pages/trainning_detail"));
const InvoiceSeriesEdit =  lazy(() => import("./modules/financial/pages/InvoiceSeriesEdit"));
const InvoiceSeriesCreate =  lazy(() => import("./modules/financial/pages/InvoiceSeriesCreate"));
const InvoiceSeriesView =  lazy(() => import("./modules/financial/pages/InvoiceSeriesView"));
const ClientCreate = lazy(() => import("./modules/registration/pages/ClientCreate"));
const PrivateRoute =  lazy(() => import("./security/PrivateRoute"));
const InvoiceProductCreate =  lazy(() => import("./modules/financial/pages/InvoiceProductCreate"));
const ProductCreate = lazy(() => import ("./modules/registration/pages/ProductCreate"));
const InvoiceServiceCreate = lazy(() => import ("./modules/financial/pages/InvoiceServiceCreate"));
const NotificationsDefault =  lazy(() => import("./modules/profile/pages/notifications"));
const InvoiceDevolutionCreate =  lazy(() => import("./modules/financial/pages/InvoiceDevolutionCreate"));
const BillsToReceive =  lazy(() => import("./modules/financial/pages/BillsToReceive"));
const BillsToReceiveCreate =  lazy(() => import("./modules/financial/pages/BillsToReceiveCreate"));
const BillsToReceiveView =  lazy(() => import("./modules/financial/pages/BillsToReceiveView"));
const BillsToPay =  lazy(() => import("./modules/financial/pages/BillsToPay"));
const BillsToPayCreate =  lazy(() => import("./modules/financial/pages/BillsToPayCreate"));
const BillsToPayView =  lazy(() => import("./modules/financial/pages/BillsToPayView"));
const FinancialAnalysis =  lazy(() => import("./modules/financial/pages/FinancialAnalysis"));
const InvoiceServiceEdit =  lazy(() => import("./modules/financial/pages/InvoiceServiceEdit"));
const InvoiceSeriesProduct =  lazy(() => import("./modules/financial/pages/InvoiceSeriesProduct"));
const InvoiceSeriesProductCreate = lazy(() => import ("./modules/financial/pages/InvoiceSeriesProductCreate"));
const Terms =  lazy(() => import("./modules/consultancy/pages/terms"));
const InvoiceProductView = lazy(() => import("./modules/financial/pages/InvoiceProductView"));
const InvoiceManual =  lazy(() => import("./modules/financial/pages/InvoiceManual"));
const InvoiceManualPage =  lazy(() => import("./modules/financial/pages/InvoiceManualPage"));
const Template =  lazy(() => import("./modules/home/Template"));
const Teste = lazy(() => import("./modules/test"));
const NotFound = lazy(() => import("./NotFound"));

const LandingPage = lazy(() => import("./modules/landingPage"));
//teste3
ReactDOM.render(
  <>
    <ToastContainer />
    <TourProvider
      accessibilityOptions={{ closeButtonAriaLabel: 'Fechar',}}
      steps={stepsLogin}
      showNavigation={false}
      showBadge={false}
      showCloseButton={true}
      //Desabilitar scroll.
      // afterOpen={disableBody}
      // beforeClose={enableBody}
    >
    <BrowserRouter>
      <NotificationContainer />
      <HelmetProvider>


        <Suspense fallback={  <Loading color={"rgba(88,197,198,1)"}  width="150px" height="150px"  />  }
      >
           <Switch>
          {/* Página Inicial */}
          <Route path="/" exact component={LandingPage} />
          <Route path="/test" exact component={Teste} />

          <PrivateRoute path="/home" exact component={Home} />

          {/* Rotas de Perfil */}
          <Route path="/login" exact component={Login} />
          <Route path="/login/:token" exact component={Login} />
          <PrivateRoute path="/profile" exact component={CompanyData} />
          <PrivateRoute path="/profile/edit" exact component={EditProfile} />
          <Route path="/profile/recovery" exact component={Recovery} />

          <Route path="/profileMenu" exact component={ProfileMenu} />

          <Route path="/profile/reset/:token" exact component={Reset} />
          <PrivateRoute
            path="/profile/digital_certificate"
            exact
            component={SaveCertificate}
          />
          <Route
            path="/profile/resend_activate_token"
            exact
            component={ResendActivateToken}
          />
          <Route
            path="/profile/resend_activate_token/:token"
            exact
            component={ResendActivateToken}
          />
          <PrivateRoute
            path="/profile/change_email/:token"
            exact
            component={ChangeEmail}
          />
          <PrivateRoute
            path="/notifications"
            exact
            component={NotificationsDefault}
          />

          {/* Rotas do Módulo Financeiro */}
          <PrivateRoute
            path="/financial/annual_declaration"
            exact
            component={AnnualDeclaration}
          />
          <PrivateRoute
            path="/financial/annual_declaration/create/:yearReference/:isRectifier"
            exact
            component={AnnualDeclarationCreate}
          />
          <PrivateRoute
            path="/financial/invoice_devolution"
            exact
            component={InvoiceDevolution}
          />
          <PrivateRoute
            path="/financial/invoice_devolution/create"
            exact
            component={InvoiceDevolutionCreate}
          />
          <PrivateRoute
            path="/financial/invoice_devolution/view"
            exact
            component={InvoiceDevolutionCreate}
          />
          <PrivateRoute
            path="/financial/invoice_product"
            exact
            component={InvoiceProduct}
          />
          <PrivateRoute
            path="/financial/invoice_product/create"
            exact
            component={InvoiceProductCreate}
          />
          <PrivateRoute
            path="/financial/invoice_product/edit/:id"
            exact
            component={InvoiceProduct}
          />
          <PrivateRoute
            path="/financial/invoice_product/view/:id"
            exact
            component={InvoiceProductView}
          />
          <PrivateRoute
            path="/financial/invoice_manual/product"
            exact
            component={InvoiceManual}
          />
          <PrivateRoute
            path="/financial/invoice_manual/product/create"
            exact
            component={InvoiceManualPage}
          />
          <PrivateRoute
            path="/financial/invoice_manual/product/edit/:id"
            exact
            component={InvoiceManualPage}
          />
          <PrivateRoute
            path="/financial/invoice_manual/product/view/:id"
            exact
            component={InvoiceManualPage}
          />
          <PrivateRoute
            path="/financial/invoice_manual/service"
            exact
            component={InvoiceManual}
          />
          <PrivateRoute
            path="/financial/invoice_manual/service/create"
            exact
            component={InvoiceManualPage}
          />
          <PrivateRoute
            path="/financial/invoice_manual/service/edit/:id"
            exact
            component={InvoiceManualPage}
          />
          <PrivateRoute
            path="/financial/invoice_manual/service/view/:id"
            exact
            component={InvoiceManualPage}
          />
          <PrivateRoute
            path="/financial/invoice_series_service/create"
            exact
            component={InvoiceSeriesCreate}
          />
          <PrivateRoute
            path="/financial/invoice_series_service/edit/:id"
            exact
            component={InvoiceSeriesEdit}
          />
          <PrivateRoute
            path="/financial/invoice_series_service/view/:id"
            exact
            component={InvoiceSeriesView}
          />
          <PrivateRoute
            path="/financial/invoice_series_service"
            exact
            component={InvoiceSeries}
          />
          <PrivateRoute
            path="/financial/invoice_service"
            exact
            component={InvoiceService}
          />
          <PrivateRoute
            path="/financial/invoice_service/create"
            exact
            component={InvoiceServiceCreate}
          />
          <PrivateRoute
            path="/financial/invoice_service/view"
            exact
            component={InvoiceServiceCreate}
          />
          <PrivateRoute
            path="/financial/invoice_service/view/:id"
            exact
            component={InvoiceServiceEdit}
          />
          <PrivateRoute
            path="/financial/invoice_service/edit/:id"
            exact
            component={InvoiceServiceEdit}
          />
          <PrivateRoute path="/financial/pay_tax" exact component={PayTax} />
          <PrivateRoute
            path="/financial/bills_to_receive"
            exact
            component={BillsToReceive}
          />
          <PrivateRoute
            path="/financial/bills_to_receive/create"
            exact
            component={BillsToReceiveCreate}
          />
          <PrivateRoute
            path="/financial/bills_to_receive/view/:id"
            exact
            component={BillsToReceiveView}
          />
          <PrivateRoute
            path="/financial/bills_to_receive/edit/:id"
            exact
            component={BillsToReceiveCreate}
          />
          <PrivateRoute
            path="/financial/bills_to_pay"
            exact
            component={BillsToPay}
          />
          <PrivateRoute
            path="/financial/bills_to_pay/create"
            exact
            component={BillsToPayCreate}
          />
          <PrivateRoute
            path="/financial/bills_to_pay/view/:id"
            exact
            component={BillsToPayView}
          />
          <PrivateRoute
            path="/financial/bills_to_pay/edit/:id"
            exact
            component={BillsToPayCreate}
          />
          <PrivateRoute
            path="/financial/financial_analysis"
            exact
            component={FinancialAnalysis}
          />
          <PrivateRoute
            path="/financial/invoice_series_product"
            exact
            component={InvoiceSeriesProduct}
          />
          <PrivateRoute
            path="/financial/invoice_series_product/create"
            exact
            component={InvoiceSeriesProductCreate}
          />
          <PrivateRoute
            path="/financial/invoice_series_product/:method/:id"
            exact
            component={InvoiceSeriesProductCreate}
          />
          <PrivateRoute
            path="/financial/invoice_series_product/:method/:id"
            exact
            component={InvoiceSeriesProductCreate}
          />

          {/* Rotas do Módulo Cadastros Gerais */}
          <PrivateRoute
            path="/registration/product"
            exact
            component={Product}
          />
          <PrivateRoute
            path="/registration/product/create"
            exact
            component={ProductCreate}
          />
          <PrivateRoute
            path="/registration/product/edit/:id"
            exact
            component={ProductCreate}
          />
          <PrivateRoute
            path="/registration/product/view/:id"
            exact
            component={ProductCreate}
          />
          <PrivateRoute path="/registration/client" exact component={Client} />
          <PrivateRoute
            path="/registration/client/create"
            exact={true}
            component={ClientCreate}
          />
          <PrivateRoute
            path="/registration/client/edit/:id"
            exact={true}
            component={ClientCreate}
          />
          <PrivateRoute
            path="/registration/client/view/:id"
            exact={true}
            component={ClientCreate}
          />
          <PrivateRoute
            path="/registration/service"
            exact
            component={Service}
          />
          <PrivateRoute
            path="/registration/service/create"
            exact
            component={ServicePage}
          />
          <PrivateRoute
            path="/registration/service/update/:id"
            exact
            component={ServicePage}
          />
          <PrivateRoute
            path="/registration/service/view/:id"
            exact
            component={ServicePage}
          />
          <PrivateRoute
            path="/registration/provider"
            exact
            component={Provider}
          />
          <PrivateRoute
            path="/registration/provider/create"
            exact
            component={ProviderCreate}
          />
          <PrivateRoute
            path="/registration/provider/edit/:id"
            exact
            component={ProviderEdit}
          />
          <PrivateRoute
            path="/registration/provider/view/:id"
            exact
            component={ProviderView}
          />

          {/* Rotas do Módulo de Treinamento */}
          <PrivateRoute path="/training" exact component={Training} />
          <PrivateRoute
            path="/training/detail/:id"
            exact
            component={TrainingDetail}
          />

          {/* Rotas do Módulo de Consultoria */}
          <PrivateRoute path="/consultancy" exact component={Consultancy} />
          <PrivateRoute
            path="/consultancy/create"
            exact
            component={ConsultancyCreate}
          />
          <PrivateRoute
            path="/consultancy/edit/:id"
            exact
            component={ConsultancyCreate}
          />
          <PrivateRoute path="/consultancy/terms" exact component={Terms} />

          {/* Rotas do Módulo de Consultoria */}
          <PrivateRoute
            path="/information/legal_information"
            exact
            component={LegalInformation}
          />
          <PrivateRoute path="/template" exact component={Template} />
          <Route path="/information/use_terms" exact component={UseTerms} />

          {/* Serviços */}
          <PrivateRoute path="/service" exact component={Services} />

          {/* Rota de Página Não Encontrada */}
          <Route component={NotFound} />

        </Switch>
        </Suspense>
      </HelmetProvider>
    </BrowserRouter>
    </TourProvider>
  </>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
