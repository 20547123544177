import React from "react";
import HeaderLogin from "../../../global/molecules/HeaderLogin";
import {
  ContainerFooter,
  ContainerDiv1,
  ContainerDiv2,
  ContainerDiv2Icons,
  ContainerDiv3,
  ContainerDiv3Icons,
} from "./styles";
import whatsapp from "../../../../imgs/icons/Whats.svg";
import whatsappHover from "../../../../imgs/icons/Whats-hover.svg";
import telefone from "../../../../imgs/icons/Telefone.svg";
import telefoneHover from "../../../../imgs/icons/Telefone-hover.svg";
import facebook from "../../../../imgs/icons/Facebook.svg";
import facebookHover from "../../../../imgs/icons/Facebook-hover.svg";
import instagram from "../../../../imgs/icons/Instagram.svg";
import instagramHover from "../../../../imgs/icons/Instagram-hover.svg";
import youtube from "../../../../imgs/icons/YouTube.svg";
import youtubeHover from "../../../../imgs/icons/YouTube-hover.svg";
import linkedin from "../../../../imgs/icons/Linkedin.svg";
import linkedinHover from "../../../../imgs/icons/Linkedin-hover.svg";

export default function Footer() {
  const [isTelefoneHovered, setIsTelefoneHovered] = React.useState(false);
  const [isWhatsappHovered, setIsWhatsappHovered] = React.useState(false);
  const [isFacebookHovered, setIsFacebookHovered] = React.useState(false);
  const [isInstagramHovered, setIsInstagramHovered] = React.useState(false);
  const [isYouTubeHovered, setIsYouTubeHovered] = React.useState(false);
  const [isLinkedinHovered, setIsLinkedinHovered] = React.useState(false);

  const handleTelefoneHover = () => {
    setIsTelefoneHovered(!isTelefoneHovered);
  };

  const handleWhatsappHover = () => {
    setIsWhatsappHovered(!isWhatsappHovered);
  };

  const handleFacebookHover = () => {
    setIsFacebookHovered(!isFacebookHovered);
  };

  const handleInstagramHover = () => {
    setIsInstagramHovered(!isInstagramHovered);
  };

  const handleYouTubeHover = () => {
    setIsYouTubeHovered(!isYouTubeHovered);
  };

  const handleLinkedinHover = () => {
    setIsLinkedinHovered(!isLinkedinHovered);
  };

  return (
    <>
      <ContainerFooter>
        <ContainerDiv1>
          <span>
            <h2>Termos e Políticas</h2>
            <a href="/information/use_terms" target="_blank">
              <p>
                Termos de Uso e Politícas de <br></br> Privacidade
              </p>
            </a>
          </span>
        </ContainerDiv1>
        <ContainerDiv2>
          <span>
            <h2>Central de Atendimento</h2>
            <p>
              Tem alguma dúvida? Entre em contato pelo nosso e-mail? <br></br>
              <b style={{ fontFamily: "Source Sans Pro" }}>
                portaldomei@acsp.com.br
              </b>
            </p>
            <p>
              Entre em contato pelo nosso WhatsApp ou fale conosco pelo
              telefone, de segunda à sexta, das{" "}
              <b style={{ fontFamily: "Source Sans Pro" }}> 9h </b> às
              <b style={{ fontFamily: "Source Sans Pro" }}>18h</b>.
            </p>
            <ContainerDiv2Icons>
              <img
                src={isWhatsappHovered ? whatsappHover : whatsapp}
                onMouseEnter={handleWhatsappHover}
                onMouseLeave={handleWhatsappHover}
              />
              <p>(11) 3180 - 3737</p>

              <img
                src={isTelefoneHovered ? telefoneHover : telefone}
                onMouseEnter={handleTelefoneHover}
                onMouseLeave={handleTelefoneHover}
              />
              <p>(11) 3180 - 3737</p>
            </ContainerDiv2Icons>
          </span>
        </ContainerDiv2>
        <ContainerDiv3>
          <span>
            <h2>Nossas Redes</h2>
            <ContainerDiv3Icons>
              <a
                href="https://www.facebook.com/associacaocomercialsp/"
                target="_blank"
              >
                <img
                  src={isFacebookHovered ? facebookHover : facebook}
                  onMouseEnter={handleFacebookHover}
                  onMouseLeave={handleFacebookHover}
                />
              </a>
              <a href="https://www.instagram.com/acsp_oficial/" target="_blank">
                <img
                  src={isInstagramHovered ? instagramHover : instagram}
                  onMouseEnter={handleInstagramHover}
                  onMouseLeave={handleInstagramHover}
                />
              </a>
              <a
                href="https://www.youtube.com/user/acspdigital/"
                target="_blank"
              >
                <img
                  src={isYouTubeHovered ? youtubeHover : youtube}
                  onMouseEnter={handleYouTubeHover}
                  onMouseLeave={handleYouTubeHover}
                />
              </a>
              <a href="https://www.linkedin.com/company/acsp/" target="_blank">
                <img
                  src={isLinkedinHovered ? linkedinHover : linkedin}
                  onMouseEnter={handleLinkedinHover}
                  onMouseLeave={handleLinkedinHover}
                />
              </a>
            </ContainerDiv3Icons>
          </span>
        </ContainerDiv3>
      </ContainerFooter>
      <div className="containerColor" style={{ backgroundColor: "#7B7B7B" }}>
        <HeaderLogin disableLogin={true} />
      </div>
    </>
  );
}
