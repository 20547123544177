import "../App.css";
import PageDefault from "../../../components/pageDefault";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClipboardList,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import FontAwesomeIconButtonDefault from "../../../components/buttonWithFontAwesomeIcon";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import ButtonActionDefault from "../../../components/buttonAction";
import {
  cancelAssociatedInvoiceService,
  fetchAssociatedInvoiceServices,
} from "../../../services/associatedInvoiceService";
import { getAllInvoiceSerie } from "../../../services/invoiceSerie";
import DialogAutoOpen from "../../../components/dialogAutoOpen";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Currency from "../../../components/inputs/currency";
import moment from "moment";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Button, Toast } from "react-bootstrap";
import { Button as ButtonMaterial } from "@mui/material";
import { Link } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import { amountNfsIssued } from "../../../services/associate/nfe";
import { SubscriptionPlan } from "../../../enumerations/Associated/subscriptionPlan";
import jwt_decode from "jwt-decode";
import { cancelNFe } from "../../../services/associate/nfe";
import typeInvoiceEnum from "../../../enumerations/typeInvoiceEnum";
import ModalWarningUpdate from "../../../components/global/ModalWarningUpdate";
import { toast } from "react-toastify";
import * as Yup from "yup";
import ButtonSubmit from "../../../components/buttonSubmit";

import {
  correctionNfe,
} from "../../../services/associate/nfe";
function InvoiceService() {
  const [openModal, setOpenModal] = useState(false);
  const [requestStatus, setRequestStatus] = useState(null);
  const [loadingBackdrop, setLoadingBackdrop] = useState(false);
  const [backendErros, setBackendErrors] = useState(undefined);

  useEffect(() => {
    const AlreadyShown = sessionStorage.getItem("WarningUpdateShownService");
    if (!AlreadyShown) {
      setOpenModal(true);
      sessionStorage.setItem("WarningUpdateShownService", true);
    }
  }, []);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [anchorEl, setAnchorEl] = useState([]);

  const handleClick = (event, idx) => {
    let array = [...anchorEl];
    array[idx] = event.currentTarget;
    setAnchorEl(array);
  };
  const handleClose = (event, idx) => {
    let array = [...anchorEl];
    array.splice(idx, 1);
    // array[idx] = event.currentTarget;

    setAnchorEl(array);
  };
  const open = Boolean(anchorEl);

  const history = useHistory();
  const [thereIsRegisteredSerie, setThereIsRegisteredSerie] = useState(true);

  const [associatedInvoiceServices, setAssociatedInvoiceServices] = useState(
    []
  );

  const schemaCorrection = Yup.object().shape({
    observation: Yup.string()
      .typeError("Campo inválido")
      .required("Campo obrigatório!")
      .min(15, "O campo aceita no mínimo 15 e no máximo 1000 caracteres")
      .max(1000, "O campo aceita no mínimo 15 e no máximo 1000 caracteres"),
  });

  const [idCancellation, setIdCancellation] = useState(null);
  const [idCorrection, setIdCorrection] = useState(null);

  const [justification, setJustification] = useState("");
  const [justificationError, setJustificationError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalCancellation, setShowModalCancellation] = useState(false);
  const [showModalCorrection, setShowModalCorrection] = useState(false);

  const [limitNfIssuedReached, setLimitNfIssuedReached] = useState(false);
  const [planoAtivo, setplanoAtivo] = useState(false);
  const prazoMaximoCancelamentoNFSe = 7; // prazo máximo em dias para cancelamento da NFSe

  useEffect(() => {
    const currentDate = moment().format("YYYY-MM-DDTHH:mm:ss");
    const token = localStorage.getItem("jwt_auth");
    const { planoAssinatura } = jwt_decode(token);
    setplanoAtivo(true);
    if (currentDate > planoAssinatura.dataTerminoVigencia) {
      setplanoAtivo(false);
    }
    amountNfsIssued()
      .then(({ data }) => {
        if (planoAssinatura !== null) {
          if (
            planoAssinatura.id === SubscriptionPlan.FREE &&
            data.amount >= planoAssinatura.limiteEmissaoNF
          ) {
            setLimitNfIssuedReached(true);
          } else {
            setLimitNfIssuedReached(false);
          }
        }
      })
      .catch((error) => console.log(error));
    fetchAssociatedInvoiceServices().then(({ data }) =>
      setAssociatedInvoiceServices(data.results)
    );
  }, []);

  const handleInvoiceCreate = () => {
    getAllInvoiceSerie()
      .then(({ data }) => {
        setThereIsRegisteredSerie(data.length > 0);
        if (thereIsRegisteredSerie)
          history.push("/financial/invoice_service/create");
        else setShowModal(true);
      })
      .catch((err) => {
        setThereIsRegisteredSerie(false);
        setShowModal(true);
      });
  };

  const handleInvoiceCancelConfirmation = (id) => {
    const invoiceFilter = associatedInvoiceServices.filter(
      (invoice) => invoice.id === id
    );
    if (invoiceFilter.length) {
      setIdCancellation(id);
      setJustification("");
      setShowModalCancellation(true);
    }
  };

  const handleJustificationChange = (e) => {
    console.log(e.target.value);
    setJustificationError("");
    setJustification(e.target.value);
  };

  const handleInvoiceCancelSubmit = async () => {
    if (!justification && !justification.length > 14) {
      setJustificationError(
        "Por favor, preencha o motivo do cancelamento com no mínimo 15 caracteres"
      );
      return;
    }
    await cancelNFe({
      id: idCancellation,
      body: { idBasket: idCancellation, justification: justification },
    })
      .then(() => {
        setShowModalCancellation(false);
        fetchAssociatedInvoiceServices().then(({ data }) =>
          setAssociatedInvoiceServices(data)
        );
        toast.success("Nota fiscal cancelada com sucesso.");
        setJustification("");
        window.location.reload();
      })
      .catch((err) => {
        let messageError = err.response.data.message;
        err.response.data.errors?.forEach(({ fieldName, message }) => {
          if (fieldName) {
            if (message) messageError = message;
          }
        });
        setJustificationError(messageError);
      });
  };

  const handleInvoiceCorrection = async ({ id }) => {

  const invoiceFilter = associatedInvoiceServices.filter(
    (invoice) => invoice.id === id
  );
  if (invoiceFilter.length) {
    setIdCorrection(id);
    setJustification("");
    setShowModalCorrection(true);
  }
}

const handleStatusCorrection = (status) => {
  switch (status) {
    case true: {
      return (
        <span className="text-success mt-2">
          Solicitação de envio de carta de correção da nota fiscal efetuada com sucesso
        </span>
      );
    }
    case false: {
      return (
        <span className="text-danger mt-2">
          {backendErros?.errors || "Erro ao processar solicitação!"}
        </span>
      );
    }
    default: {
      return <></>;
    }
  }
};




  const handleInvoiceCorrectionSubmit = async ({ data }) => {
    setLoadingBackdrop(true);
    const { observation, idCorrection } = data;
    await correctionNfe({
      id: idCorrection,
      body: { idBasket: idCorrection, justification: observation },
    })
      .then(() => {
        setRequestStatus(true);
        setShowModalCorrection(false);
        setRequestStatus(undefined);
        toast.success("Carta de correção enviada com sucesso.");
        window.location.reload();
      })
      .catch((err) => {
        setLoadingBackdrop(false);
        setRequestStatus(false);

        if (err.response && Array.isArray(err.response?.data?.errors)) {
          err.response.data.errors.forEach((e) =>
            setBackendErrors({ [e.fieldName]: e.message })
          );
        }
      });
    setLoadingBackdrop(false);
  };


  const options = {
    selectableRows: false,
    onDownload: (buildHead, buildBody, columns, data) => {
      const splice = columns.splice(columns.length - 1);
      return (
        buildHead(columns) +
        buildBody(
          associatedInvoiceServices.map((currentInvoiceService, index) => {
            return {
              index: associatedInvoiceServices.length - 1,
              data: [
                currentInvoiceService.associatedServiceSerieId,
                moment
                  .utc(currentInvoiceService.createdAt)
                  .format("DD/MM/YYYY"),
                currentInvoiceService.codStatus,
                currentInvoiceService.associatedClient.socialName,
                currentInvoiceService.codStatus === 1
                  ? "SUCESSO"
                  : currentInvoiceService.motivo,
                currentInvoiceService.cancelada === true ? "SIM" : "NÃO",
              ],
            };
          })
        )
      );
    },
    download: false,
    search: false,
    filter: false,
    print: false,
    viewColumns:false,
    textLabels: {
      body: {
        noMatch: "Nenhum registro encontrado!",
        toolTip: "Ordenação",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página Anterior",
        rowsPerPage: "Linhas por página: ",
      },
      filter: {
        all: "Todos resultados",
        title: "Seus filtros",
        reset: "",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download csv",
        filterTable: "Filtrar tabela",
        print: "Imprimir",
        viewColumns: "Filtro de coluna",
      },
    },
  };

  const columns = [
    {
      name: "Série-NF",
      options: {
        filter: true,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
      },
    },
    {
      name: "Nº Nota Fiscal",
      options: {
        filter: true,
        setCellHeaderProps: (value) => ({ style: { textAlign: "left" } }),
      },
    },
    {
      name: "Data de emissão",
      options: {
        filter: false,
      },
    },
    {
      name: "Cliente",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Valor da nota",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
      },
    },
    {
      name: "Status de processamento:",

      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip
              disableHoverListener={
                associatedInvoiceServices[tableMeta.rowIndex]?.codStatus === 1
                  ? false
                  : true
              }
              title={`${associatedInvoiceServices[tableMeta.rowIndex]?.motivo}`}
            >
              <span>
                <center>{value}</center>
              </span>
            </Tooltip>
          );
        },
      },
    },

    {
      name: "Ações",
      options: {
        filter: false,
        sort: false,
        print: false,
        export: false,
        setCellHeaderProps: (value) => ({ style: { textAlign: "start" } }),
      },
    },
  ];

  const confirmationModalCorrection = (
    <DialogAutoOpen
      dialogData={{
        size: "lg",
        body: (
          <div className="row col-12 col-md-12 m-0 p-0">
            <div className="col-sm-12">
              <p className="text-muted">
                {" "}
                Esta ação é irreversível! Deseja realmente corrigir a nota
                fiscal?
              </p>
            </div>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              validationSchema={schemaCorrection}
              initialValues={{ observation: undefined,idCorrection: idCorrection }}
              onSubmit={(data) => handleInvoiceCorrectionSubmit({ data })}
              children={(field) => (
                <Form className="row col-12 m-0 p-0 justify-content-sm-center justify-content-md-start">
                  <div className="col-12 col-sm-12">
                    <div className="form-group">
                      <p className="m-0">
                        <span className="text-danger">*</span>
                        Justificativa para correção
                      </p>
                      <div className="input-group">
                        <span
                          style={{ height: 150 }}
                          className="input-group-prepend input-group-text bg-white"
                        >
                          <FontAwesomeIcon
                            color="#64c2c8"
                            icon={faClipboardList}
                          />
                        </span>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Field
                            style={{ width: 600, height: 150 }}
                            as="textarea"
                            rows="4"
                            name="observation"
                            maxLength="1000"
                            className="form-control"
                            placeholder="Informe os dados a serem corrigidos e o motivo."
                          />
                          {console.log(field)}
                            <div style={{display:'flex'}}>
                          <span
                            style={{
                              fontSize: 12,
                              justifyContent: "flex-end",
                              marginTop: "auto",
                              marginLeft: "auto",
                            }}
                          >
                            Máximo de Caracteres: (
                            {field.values && field.values.observation
                              ? field.values.observation.length
                              : "0"}
                            /1000)
                          </span>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        className="text-danger col-12 p-0"
                        component="small"
                        name="observation"
                      />
                    </div>
                    <div className="form-row justify-content-center">
                      <div className="col-sm-4">
                        <ButtonSubmit
                          buttonData={{
                            variant: "outline-info",
                            text: "Sim",
                            _class: "font-weight-bold col mr-1 mt-2",
                          }}
                        />
                      </div>
                      <div className="col-sm-4">
                        <ButtonActionDefault
                          className="createAccout_btn font-weight-bold col mr-1 mt-2"
                          buttonData={{
                            variant: "info",
                            text: "Não",
                            _class: `font-weight-bold col mr-1 mt-2`,
                            action: () => {
                              setShowModalCorrection(false);
                              setRequestStatus(undefined);
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      {handleStatusCorrection(requestStatus)}
                    </div>
                  </div>
                </Form>
              )}
            />
          </div>
        ),
      }}
    />
  );

  const page = (
    <div
      className=""
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* <div className="row ml-lg-3">
        <ButtonMaterial
          onClick={handleClickOpen}
          variant="contained"
          sx={{
            marginBottom: 2,
            backgroundColor: "#D70F57",
            fontColor: "#FFF",
            "&:hover": {
              backgroundColor: "#D70F57",
            },
          }}
          endIcon={<ErrorOutlineIcon />}
        >
          Procedimento Importante{" "}
        </ButtonMaterial>
      </div>
      <div className="alert alert-danger mt-4" style={{ width: "72vw" }}>
        Para emissão de nota fiscal é necessário possuir certificado digital,
        caso deseje contratar{" "}
        <a
          href="https://acsp.com.br/lp-certificado-home"
          className="alert-link"
          target="_blank"
        >
          clique aqui
        </a>{" "}
        e contrate de forma rápida e fácil.
      </div> */}
      {limitNfIssuedReached && planoAtivo ? (
        // <div className="alert alert-warning" style={{ width: "72vw" }}>
        //   O número máximo de emissão de Notas Fiscais foi atingido
        // </div>
        <div style={{ width: "72vw" }}>
          <div
            className="mt-5"
            style={{
              width: "601px",
              height: "44px",
              borderRadius: "5px",
              backgroundColor: "#D70F57",
              color: "#FFF",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ marginLeft: "-10px" }}>
              <ErrorOutlineIcon />
            </div>
            <span
              style={{
                paddingLeft: "10px",
                lineHeight: "18px",
                fontSize: "14px",
              }}
            >
              O número máximo de emissão de Notas Fiscais foi atingido. 
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
      {!planoAtivo ? (
          <div style={{ width: "72vw" }}>
            <div
              className=""
              style={{
                width: "601px",
                height: "44px",
                borderRadius: "5px",
                backgroundColor: "#D70F57",
                color: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginLeft: "-10px" }}>
                <ErrorOutlineIcon />
              </div>
              <span
                style={{
                  paddingLeft: "10px",
                  lineHeight: "18px",
                  fontSize: "14px",
                }}
              >
               Seu plano junto a plataforma venceu, favor acionar um de nossos <br></br>especialistas para continuar emitindo notas fiscais.
              </span>
            </div>
          </div>
        ) : (
          <></>
        )}
      <div
        className="mt-5"
        style={{
          width: "72vw",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h2 style={{ color: "#002c43" }}>
          <FontAwesomeIcon
            className="back-icon mr-3 icon-hover"
            icon={faArrowLeft}
            size="1x"
            onClick={() => history.push("/home")}
            color="#002c43 icon-hover "
          />
          Notas Fiscais de serviço
        </h2>
        {limitNfIssuedReached || !planoAtivo ? (
          <>
            <Link to="invoice_product/create">
              <Button
                disabled
                style={{
                  fontWeight: "600",
                  backgroundColor: "#7B7B7B",
                  border: "none",
                }}
              >
                <span style={{ marginRight: "8px" }}>
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                Nova nota fiscal
              </Button>
            </Link>
          </>
        ) : (
          <div style={{ height: "10px", fontWeight: "normal" }}>
            <FontAwesomeIconButtonDefault
              buttonData={{
                variant: "info",
                text: "Nova nota fiscal",
                action: () => handleInvoiceCreate(),
                icon: <FontAwesomeIcon icon={faPlus} />,
              }}
            />
          </div>
        )}

        {showModal ? (
          <DialogAutoOpen
            dialogData={{
              body: (
                <div className="row justify-content-center mb-3">
                  <div className="col-sm-12">
                    <p className="text-muted">
                      Não há série de notas ativas. Deseja cadastrar?
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <ButtonActionDefault
                      buttonData={{
                        text: "Sim",
                        variant: "outline-info",
                        _class: "mt-2 col",
                        action: () =>
                          history.push(
                            "/financial/invoice_series_service/create"
                          ),
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <ButtonActionDefault
                      className="createAccout_btn font-weight-bold col mr-1 mt-2"
                      buttonData={{
                        variant: "info",
                        action: () => setShowModal(false),
                        text: "Não",
                        textColor: "#FFF",
                        _class: "font-weight-bold col mr-1 mt-2",
                      }}
                    />
                  </div>
                </div>
              ),
            }}
          />
        ) : (
          <></>
        )}

        {showModalCancellation ? (
          <DialogAutoOpen
            dialogData={{
              size: "lg",
              body: (
                <div className="row justify-content-center mb-3">
                  <div className="col-sm-12">
                    <p className="text-muted">
                      Esta ação é irreversível! Deseja realmente cancelar a nota
                      fiscal?
                    </p>
                  </div>
                  <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    validateOnMount={false}
                    initialValues={{ justification }}
                  >
                    <div className="col-sm-12">
                      <div className="form-group col-12">
                        <p className="m-0">Motivo do cancelamento:</p>
                        <div className="input-group">
                          <span
                            className={`input-group-prepend input-group-text bg-white`}
                          >
                            <FontAwesomeIcon
                              color="#64c2c8"
                              icon={faClipboardList}
                            />
                          </span>
                          <Field
                            as="textarea"
                            rows={5}
                            name="justification"
                            className="form-control form-control-sm"
                            placeholder="Informe o motivo do cancelamento"
                            onKeyUp={handleJustificationChange}
                          />
                          {justificationError && (
                            <small className="col-12 p-0 text-danger">
                              {justificationError}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </Formik>
                  <div className="col-sm-4">
                    <ButtonActionDefault
                      buttonData={{
                        text: "Sim",
                        variant: "outline-info",
                        _class: "mt-2 col",
                        action: () => handleInvoiceCancelSubmit(),
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <ButtonActionDefault
                      className="createAccout_btn font-weight-bold col mr-1 mt-2"
                      buttonData={{
                        variant: "info",
                        action: () => {
                          setShowModalCancellation(false);
                          setJustification("");
                          setIdCancellation(null);
                        },
                        text: "Não",
                        textColor: "#FFF",
                        _class: "font-weight-bold col mr-1 mt-2",
                      }}
                    />
                  </div>
                </div>
              ),
            }}
          />
        ) : (
          <></>
        )}
 {showModalCorrection && confirmationModalCorrection}
      </div>
      <div
        className="mt-4"
        style={{
          width: "72vw",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <MUIDataTable
          title={"Notas Fiscais de Serviço"}
          data={associatedInvoiceServices.map(
            (currentInvoiceService, index) => {
              const { createdAt } = currentInvoiceService;
              return [
                currentInvoiceService.serviceInvoiceSerie.name,
                currentInvoiceService.numeroNfe,
                moment
                  .utc(currentInvoiceService.createdAt)
                  .format("DD/MM/YYYY"),
                currentInvoiceService.associatedClient.socialName,
                //gravar valor nota
                "R$ " +
                  Currency({
                    value: parseFloat(currentInvoiceService?.totalValue),
                  }),

                <p>
                  {currentInvoiceService.codStatus === 1
                    ? "Sucesso"
                    : currentInvoiceService.motivo}
                </p>,

                <div>
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={(event) => handleClick(event, index)}
                  >
                    <MoreHorizIcon />
                  </IconButton>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl[index]}
                    open={anchorEl[index] ? true : false}
                    onClick={(event) => handleClose(event, index)}
                  >
                    <MenuItem
                      onClick={() => {
                        history.push(
                          `/financial/invoice_service/view/${currentInvoiceService.id}`
                        );
                      }}
                      key={index + 1001}

                    >
                      Visualizar
                    </MenuItem>

                    {currentInvoiceService.reportZip && (
                      <MenuItem
                        onClick={() => {
                          window.open(
                            currentInvoiceService.reportZip,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                        key={index + 1002}

                      >
                        Baixar Nota Fiscal
                      </MenuItem>
                    )}
                    {currentInvoiceService.codStatus === 1 &&
                      (currentInvoiceService.cancelada === false ||
                        currentInvoiceService.cancelada === null) && (
                        <MenuItem
                          onClick={() =>
                            handleInvoiceCancelConfirmation(
                              currentInvoiceService.id
                            )
                          }
                          key={index + 1003}

                        >
                          {" "}
                          Cancelar Nota Fiscal
                        </MenuItem>
                      )}

                  {currentInvoiceService.codStatus === 1 &&
                        (currentInvoiceService.correction === false ||
                          currentInvoiceService.correction === null) &&
                        (currentInvoiceService.returned === false ||
                          currentInvoiceService.returned == null) &&
                        (currentInvoiceService.cancelada === false ||
                          currentInvoiceService.cancelada === null) &&
                          currentInvoiceService.typeInvoice === typeInvoiceEnum.NORMAL && (
                          <MenuItem
                            key={index + 1004}
                            onClick={() =>
                              handleInvoiceCorrection(
                                currentInvoiceService.id
                              )
                            }
                          >
                            {" "}
                            Solicitar Carta de Correção
                          </MenuItem>
                        )}
                  </Menu>
                </div>,
              ];
            }
          )}
          columns={columns}
          options={options}
        />
      </div>
    </div>
  );

  return (
    <>
      <PageDefault
        pageData={{
          pageContent: page,
        }}
      />
      ,
      <ModalWarningUpdate handleClose={handleCloseModal} open={openModal} />
    </>
  );
}

export default InvoiceService;
